import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "./home.css"
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import Pack from '../../components/Pack';
import ProductService from '../../../services/product';

const Home = () => {

    const [packs, setPacks] = useState([]);

    const dispatch = useDispatch()

    useEffect(() => {
        allPacks();
    }, []);

    const allPacks = async () => {
        try {
            const result = await dispatch(ProductService.getAllPacks());
            setPacks(result);  // Assure-toi que 'result' est la donnée correcte
        } catch (e) {
            console.error('Error fetching packs:', e);
        }
    };
    /*
    const goToPage2 = () => {
        navigate('/nos-offres');
    };
    const goToPage3 = () => {
        navigate('/abonnements3');
    };
    const goToPage4 = () => {
        navigate('/personnalise');
    };
 */

    return (

        <div className='fontF m-2'>

            <div className="home-header-section home-header-section1 ">

                {/* BANNER-SECTION */}
                <div className="home-banner-section overflow-hidden home-banner-section2 home-banner-section1">
                    <div className="banner-container-box">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-4 text-md-left text-center d-flex align-items-center">
                                    <div className="home-banner-text " data-aos="fade-up">
                                        <h1>Prenez soin de vos proches en toute <span className="banner-deserve"> sérénité</span></h1>
                                        <p className="banner-paragraph">
                                            Des solutions adaptées pour la santé,
                                            l'énergie, l'éducation et bien plus encore.
                                            Simplifiez la vie de vos proches avec nos
                                            services fiables et transparents
                                        </p>

                                        <div className="banner-btn discover-btn-banner">
                                            <Link to="/offres" className="text-decoration-none">Découvrez Nos Packs                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="banner-img-section position-relative">
                                        <figure className="banner-img2-figure" style={{marginTop:65}}>
                                        <img src="./assets/images/imgW.png" /* style={{ maxHeight: 600 }} */  className='imagehome'/>

                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* offer-section */}
            <div className="offer-section offer-section1" data-aos="fade-up" style={{ paddingBottom: 100 }}>
                <h2>Choisissez le pack adapté à vos
                    <span className="support"> besoins</span></h2>
                <p style={{ textAlign: "center", paddingBottom: 10 }}>Découvrez nos solutions adaptées pour prendre soin de vos proches en toute sérénité. Choisissez parmi nos packs ou créez le vôtre</p>





                <div className="container" >
                    <div className="row g-4"> {/* Utilisation de g-4 pour l'espacement entre les éléments */}




                        {packs.length > 0 ? (
                            packs.map((pack) => (<Pack key={pack.id} pack={pack} />))
                        ) : (
                            <p>No packs available</p>
                        )}

                    </div>
                </div>

























            </div>
            {/* Popup-Section */}
            <div className="offer-section offer-section1 commentcamarche" data-aos="fade-up" >
                <div className=" container-fluid  containerc">
                    <h2 style={{ paddingTop: 75 }}>Comment ça marche</h2>
                    <div className="steps">
                        <div>
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-icon"><img src="./assets/images/13.png" width={50} height={50} /></div>
                            </div>
                            <div style={{ width: 250, height: 200, textAlign: 'center', marginTop: "30px" }}  >
                                <p className="step-title"> Sélection du pack</p>
                                <div className="step-description">Parcourez nos différents packs conçus pour répondre à tous les besoins. Vous pouvez également créer votre propre pack personnalisé.
                                </div>
                            </div>
                        </div>



                        <div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-icon"><img src="./assets/images/14.png" width={50} height={50} /></div>
                            </div>
                            <div style={{ width: 250, height: 200, textAlign: 'center', marginTop: "30px" }} >
                                <div className="step-title">Choix de l’offre</div>
                                <div className="step-description">Choisissez entre un paiement mensuel pour plus de flexibilité ou un abonnement annuel pour bénéficier de réductions.</div>
                            </div>
                        </div>





                        <div>

                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-icon"><img src="./assets/images/15.png" width={50} height={50} /></div>

                            </div>
                            <div style={{ width: 250, height: 200, textAlign: 'center', marginTop: "30px" }}>
                                <div className="step-title">Création du compte</div>
                                <div className="step-description">Inscrivez-vous en tant que Protecteur, puis ajoutez votre ayant droit
                                    en lui attribuant une carte de bénéficiaire.</div>
                            </div>

                        </div>




                        <div>

                            <div className="step" >
                                <div className="step-number">4</div>
                                <div className="step-icon"><img src="./assets/images/16.png" width={50} height={50} /></div>

                            </div>
                            <div style={{ width: 250, height: 200, textAlign: 'center', marginTop: "30px" }}>
                                <div className="step-title" style={{ textAlign: "center" }}>Choix du mode de paiement</div>
                                <div className="step-description payment-options" style={{ marginBottom: 70, textAlign: "center" }}>
                                    Choisissez parmi nos différentes options de paiement sécurisé et profitez d'un processus simple et rapide.
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            {/* Popup-Section */}

            <div className="offer-section popup-section position-relative entreprisetop" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <h2 data-aos="fade-up">Devenez partenaire et faites connaître <span className="support"> Vos services</span></h2>
                            <p>Rejoignez notre plateforme et proposez vos services dans des domaines variés : télécommunication, santé, énergie, éducation, services bancaires, et plus encore. Offrez à notre communauté des solutions adaptées à leurs besoins quotidiens.</p>
                            <div className="popup-btn">
                                <a href="javascript:;">Devenir partenaire</a>
                            </div>
                            <h4>Need clinical advice?</h4>
                            <h4 className="popup-section-number">0800 123 45 678</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="video-section-box">
                                <a className="popup-vimeo" href="https://previews.customer.envatousercontent.com/bfa4eae3-5be3-457e-9c5f-31e840d016e5/watermarked_preview/watermarked_preview.mp4">
                                    <figure className="mb-0">
                                        <img src="./assets/images/popup-video-img.png" style={{ cursor: 'pointer' }} alt="" className="img-fluid video-img" />
                                    </figure>
                                </a>
                                <figure className="position-absolute popup-top-figure">
                                    <img src="./assets/images/video-section-top-img.png" alt="" className="img-fluid" />
                                </figure>
                                <figure className="position-absolute popup-bottom-figure">
                                    <img src="./assets/images/video-section-bottom-img.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Counter-Section */}

            <div className="counter-section2">
                <div className="container">
                    <div className="counter-inner-row">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box vl-border">
                                    <h5><span className="counter">45</span></h5>
                                    <p>Donations Every Year</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box vl-right-border">
                                    <h5><span className="counter">690</span></h5>
                                    <p>Great Local Volunteers</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box">
                                    <h5><span className="counter">437</span></h5>
                                    <p>Face to Face Visits</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-2">
                                <div className="counter-inner-box border-right-box">
                                    <h5><span className="counter">170</span></h5>
                                    <p>Organized Local Events</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service-Offer-Section */}
            <div style={{ height: 5, marginTop: 100 }}>
                <h2 style={{ textAlign: 'center' }}>Entreprises Partenaires</h2>
                <div className='d-flex justify-content-center'>
                    <div className=''> <img src="./assets/images/17.png" alt="" className="img-fluid" width={300} height={300} /></div>
                    <div className=''> <img src="./assets/images/18.png" alt="" className="img-fluid" width={300} height={300} /></div>
                    <div className=''> <img src="./assets/images/19.png" alt="" className="img-fluid" width={300} height={300} /></div>
                    <div className=''> <img src="./assets/images/20.png" alt="" className="img-fluid" width={300} height={300} /></div>
                </div>

            </div>
            {/*Happy-Clients-Section  */}
            
            <section className="temoignage happy-clients-section happy-clients-section2" data-aos="fade-up">
  <div className="offer-section offer-section1" style={{ marginTop: 300 }} data-aos="fade-up">
    <h2>
      Temoignages <span className="support">.</span>
    </h2>

    <div className="row temoignages temoignagepadding">
      {/* Element 1 */}
      <div className="col-12 col-md-6 col-lg-3">
        <div className="client-card">
          <figure>
            <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
          </figure>
          <p className="carousel-text">J'ai trouvé tout ce dont j'avais besoin : appli mobile, assistance admin, conseils RH. Très satisfait !</p>
          <div className="about-border"></div>
          <p className="carousel-title">Laura P</p>
          <p className="carousel-end-text">Senior Advisor</p>
          <figure className="carousel-bottom-fig">
            <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
          </figure>
        </div>
      </div>

      {/* Element 2 */}
      <div className="col-12 col-md-6 col-lg-3">
        <div className="client-card">
          <figure>
            <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
          </figure>
          <p className="carousel-text">Excellente expérience ! L'application mobile est intuitive et le support client est très réactif.</p>
          <div className="about-border"></div>
          <p className="carousel-title">John D</p>
          <p className="carousel-end-text">Project Manager</p>
          <figure className="carousel-bottom-fig">
            <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
          </figure>
        </div>
      </div>

      {/* Element 3 */}
      <div className="col-12 col-md-6 col-lg-3">
        <div className="client-card">
          <figure>
            <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
          </figure>
          <p className="carousel-text">L'application mobile est facile à utiliser et l'interface est très agréable. Service client très pro !</p>
          <div className="about-border"></div>
          <p className="carousel-title">Emma L</p>
          <p className="carousel-end-text">Product Designer</p>
          <figure className="carousel-bottom-fig">
            <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
          </figure>
        </div>
      </div>

      {/* Element 4 */}
      <div className="col-12 col-md-6 col-lg-3">
        <div className="client-card">
          <figure>
            <img src="./assets/images/happy-clients-section-img2.png" alt="" className="img-fluid" />
          </figure>
          <p className="carousel-text">Une équipe réactive et professionnelle. L'outil est simple à utiliser et très fonctionnel.</p>
          <div className="about-border"></div>
          <p className="carousel-title">Paul R</p>
          <p className="carousel-end-text">UX Researcher</p>
          <figure className="carousel-bottom-fig">
            <img src="./assets/images/carousel-bottom-img.png" alt="" className="img-fluid" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>


            {/* Footer */}
        </div>

    );
};

export default Home;