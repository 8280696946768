import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Abonnement = () => {

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(21px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));


    return (
        <>
            <div style={{ textAlign: "center", marginTop: 100 }}>je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}> 4€/mois</span>
                <FormControlLabel style={{ paddingTop: 9 }}
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}

                /></div>
            <div className='container-fluid d-flex flex-row justify-content-center' style={{ marginBottom: 500, marginTop: 50 }}>
                <div className="d-flex flex-column justify-content-center" style={{ background: "rgba(56,115,184,0.1", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", width: 300, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                    <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6 }}>Basic</div>
                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>9,99€/mois</div>
                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                    <div>
                        <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>

                        </ul>
                    </div>
                    <div >
                        <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                    </div>
                    <div style={{ height: 40 }}>
                        <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} />
                    </div>
                    <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                        Exclusivité Google: -50% la première année
                    </div>


                </div>
                <div className="d-flex flex-column justify-content-center" style={{ position: "relative", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", width: 300, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                    <div style={{ position: "absolute", left: "23%", top: "-9px", background: "#3873b8", color: "#fff", paddingLeft: 4, paddingRight: 4, fontSize: 12 }}>   recommandé pour vous </div>
                    <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6, color: "#3873b8" }}>Medium</div>
                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>11,99€/mois</div>
                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                    <div>
                        <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>

                        </ul>
                    </div>
                    <div >
                        <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                    </div>
                    <div style={{ height: 40 }}>
                        <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} />
                    </div>
                    <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                        Exclusivité Google: -50% la première année
                    </div>


                </div>
                <div className="d-flex flex-column justify-content-center" style={{ background: "rgba(56,115,184,0.1", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", width: 300, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                    <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6 }}>Prenium</div>
                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>14,99€/mois</div>
                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                    <div>
                        <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>
                            <li className='p-2'>Tous les articles débloqués</li>

                        </ul>
                    </div>
                    <div >
                        <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                    </div>
                    <div style={{ height: 40 }}>
                        <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} />
                    </div>
                    <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                        Exclusivité Google: -50% la première année
                    </div>


                </div>
            </div>
        </>
    );
};


export default Abonnement;