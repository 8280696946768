import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const NewPaiement = () => {
    return (
        <div style={{ marginBottom: 200, marginTop: 50, borderRadius: 15 }} className='d-flex flex-wrap justify-content-center'>
            <div style={{ height: 285, background: 'white', boxShadow: " 0px 0px 15px #AEB1BE33", padding: "22px 30px 30px 30px" }}>
                {/*  <div className='statisfait'> Satisfait ou <br/>remboursé 30J</div> */}

                <div>
                    <p style={{ fontSize: 23, fontWeight: 'bold', marginLeft: 10 }}>Choisissez votre moyen de paiement</p>
                    <FormControl style={{ minWidth: "725px" }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <div className='d-flex flex-wrap  align-items-center justify-content-between divblue1 mb-4' style={{ height: 80 }}>
                                <div className="form-check">
                                    <FormControlLabel id='testToot' value="carte-bancaire" control={<Radio />} label="Carte bancaire" />
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    <img src="/assets/images/38.webp" alt="Logo Carte Bancaire" style={imagepaiement} />
                                </div>
                            </div>

                            {/* Option de paiement PayPal */}

                            <div className='d-flex flex-wrap  align-items-center justify-content-between divblue1 mb-4' style={{ height: 80 }}>
                                <div className="form-check">
                                    <FormControlLabel id='testToot' value="paypal" control={<Radio />} label="Paypal" />
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    <img src="/assets/images/39.webp" alt="Logo PayPal" style={imagepaiement} />
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>

                </div>



            </div>
            {/* tableau des offres  */}
            <div className='ml-5 mt-1' style={{ width: 400, height: '430px', background: 'white' }}>
                
                    <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                    </div>
                    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }} >
                        <div>
                            <p style={{ fontWeight: 'bold', fontSize: 15 }}>Formule santé</p>
                            <div className='d-flex justify-content-between'>
                                <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14 }}>Prévention (Medium) - 1 mois</p>
                                <p>46 €</p>
                            </div>
                        </div>
                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        <div >
                            <div className='d-flex justify-content-between'>
                                <p style={{ fontSize: 15 }}>Total HT</p>
                                <p style={{ fontWeight: 'bold' }}>46€</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} />      </p>
                                <p style={{ fontWeight: 'bold' }}>46€</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC </p>
                                <p style={{ fontWeight: 'bold', color: '#00A74D' }}>46€</p>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>  <button style={{ width: '100%', fontSize: 17, height: 50, fontWeight: "bold" }} className='btn btn-success' >Valider ma commande<ArrowCircleRightOutlinedIcon style={{ marginLeft: 4 }}></ArrowCircleRightOutlinedIcon></button></div>
                        <p style={{ color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: 10, textDecoration: 'underline' }}> Vous avez un code promo</p>


                    </div>
                

            </div>

        </div>
    );
};

export default NewPaiement;
const imageinfo = {
    height: 16,
    cursor: 'pointer',
    marginLeft: 1
}

const imagestyle = {
    marginTop: 7,
    marginLeft: 10,

    height: 16,
    marginRight: 10
}
const imagepaiement = {
    marginTop: 5,
    marginLeft: 10,

    height: 30,
    marginRight: 5
}