import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useNavigate } from 'react-router-dom';
import { logout } from '../../store/actions';

const AuthManager = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  // Vérifier si le token est dans le localStorage quand le composant est monté
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);  // L'utilisateur est connecté
    }
  }, []);

  
  const handleLogout = () => {
    
    localStorage.removeItem('authToken');
    localStorage.removeItem('userInfo');
    setIsLoggedIn(false);
    dispatch(logout())
    history('/login');
  };

  return (
    <div>
      {children(isLoggedIn, handleLogout)} {/* On passe isLoggedIn et handleLogout via props */}
    </div>
  );
};

export default AuthManager;
