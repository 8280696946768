import React from 'react';

const invoice = () => {
    return (
        <div>
  <div className="rts-invoice-style-one">
    <div className="container-2">
      <div className="row">
        <div className="col-lg-12">
          <div className="invoice-main-wrapper-1">
            <div className="logo-top-area">
              <div className="logo">
                <img src="assets/images/logo/logo-01.svg" alt="logo" />
              </div>
              <div className="invoice-location">
                <h6 className="title">Invoice</h6>
                <span className="number">0152646678</span>
                <span className="email">info@reactheme.com</span>
                <span className="website">https://reacthemes.com/</span>
              </div>
            </div>
            <div className="invoice-banner bg_image">
            </div>
            <div className="invoice-center-rts">
              <div className="table-responsive">
                <table className="table table-striped invoice-table">
                  <thead className="bg-active">
                    <tr>
                      <th>Item Item</th>
                      <th className="text-center">Unit Price</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="item-desc-1">
                          <span>Field Roast Chao Cheese Creamy Original</span>
                          <small>SKU: FWM15VKT</small>
                        </div>
                      </td>
                      <td className="text-center">$10.99</td>
                      <td className="text-center">1</td>
                      <td className="text-right">$10.99</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="item-desc-1">
                          <span>Blue Diamond Almonds Lightly Salted</span>
                          <small>SKU: FWM15VKT</small>
                        </div>
                      </td>
                      <td className="text-center">$20.00</td>
                      <td className="text-center">3</td>
                      <td className="text-right">$60.00</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="item-desc-1">
                          <span>Fresh Organic Mustard Leaves Bell Pepper</span>
                          <small>SKU: KVM15VK</small>
                        </div>
                      </td>
                      <td className="text-center">$640.00</td>
                      <td className="text-center">1</td>
                      <td className="text-right">$640.00</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="item-desc-1">
                          <span>All Natural Italian-Style Chicken Meatballs</span>
                          <small>SKU: 98HFG</small>
                        </div>
                      </td>
                      <td className="text-center">$240.00</td>
                      <td className="text-center">1</td>
                      <td className="text-right">$240.00</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-end f-w-600">SubTotal</td>
                      <td className="text-right">$1710.99</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-end f-w-600">Tax</td>
                      <td className="text-right">$85.99</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-end f-w-600">Grand Total</td>
                      <td className="text-right f-w-600">$1795.99</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="invoice-area-bottom">
              <div className="powerby">
                <p>Powerby</p>
                <img src="assets/images/logo/fav.png" alt />
              </div>
              <p>Note:This is computer generated receipt and does not require physical signature.</p>
            </div>
          </div>
          <div className="buttons-area-invoice no-print mb--30">
            <a href="javascript:window.print()" className="rts-btn btn-primary radious-sm with-icon">
              <div className="btn-text">
                Print Now
              </div>
              <div className="arrow-icon">
                <i className="fa-regular fa-print" />
              </div>
              <div className="arrow-icon">
                <i className="fa-regular fa-print" />
              </div>
            </a>
            <a href="assets/images/invoice/invoice.pdf" download className="rts-btn btn-primary radious-sm with-icon">
              <div className="btn-text">
                Download
              </div>
              <div className="arrow-icon">
                <i className="fa-thin fa-download" />
              </div>
              <div className="arrow-icon">
                <i className="fa-thin fa-download" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="product-details-popup-wrapper">
    <div className="rts-product-details-section rts-product-details-section2 product-details-popup-section">
      <div className="product-details-popup">
        <button className="product-details-close-btn"><i className="fal fa-times" /></button>
        <div className="details-product-area">
          <div className="product-thumb-area">
            <div className="cursor" />
            <div className="thumb-wrapper one filterd-items figure">
              <div className="product-thumb zoom" onmousemove="zoom(event)" style={{backgroundImage: 'url(assets/images/products/product-details.jpg)'}}><img src="assets/images/products/product-details.jpg" alt="product-thumb" />
              </div>
            </div>
            <div className="thumb-wrapper two filterd-items hide">
              <div className="product-thumb zoom" onmousemove="zoom(event)" style={{backgroundImage: 'url(assets/images/products/product-filt2.jpg)'}}><img src="assets/images/products/product-filt2.jpg" alt="product-thumb" />
              </div>
            </div>
            <div className="thumb-wrapper three filterd-items hide">
              <div className="product-thumb zoom" onmousemove="zoom(event)" style={{backgroundImage: 'url(assets/images/products/product-filt3.jpg)'}}><img src="assets/images/products/product-filt3.jpg" alt="product-thumb" />
              </div>
            </div>
            <div className="product-thumb-filter-group">
              <div className="thumb-filter filter-btn active" data-show=".one"><img src="assets/images/products/product-filt1.jpg" alt="product-thumb-filter" /></div>
              <div className="thumb-filter filter-btn" data-show=".two"><img src="assets/images/products/product-filt2.jpg" alt="product-thumb-filter" /></div>
              <div className="thumb-filter filter-btn" data-show=".three"><img src="assets/images/products/product-filt3.jpg" alt="product-thumb-filter" /></div>
            </div>
          </div>
          <div className="contents">
            <div className="product-status">
              <span className="product-catagory">Dress</span>
              <div className="rating-stars-group">
                <div className="rating-star"><i className="fas fa-star" /></div>
                <div className="rating-star"><i className="fas fa-star" /></div>
                <div className="rating-star"><i className="fas fa-star-half-alt" /></div>
                <span>10 Reviews</span>
              </div>
            </div>
            <h2 className="product-title">Wide Cotton Tunic Dress <span className="stock">In Stock</span></h2>
            <span className="product-price"><span className="old-price">$9.35</span> $7.25</span>
            <p>
              Priyoshop has brought to you the Hijab 3 Pieces Combo Pack PS23. It is a
              completely modern design and you feel comfortable to put on this hijab.
              Buy it at the best price.
            </p>
            <div className="product-bottom-action">
              <div className="cart-edit">
                <div className="quantity-edit action-item">
                  <button className="button"><i className="fal fa-minus minus" /></button>
                  <input type="text" className="input" defaultValue="01" />
                  <button className="button plus">+<i className="fal fa-plus plus" /></button>
                </div>
              </div>
              <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                <div className="btn-text">
                  Add To Cart
                </div>
                <div className="arrow-icon">
                  <i className="fa-regular fa-cart-shopping" />
                </div>
                <div className="arrow-icon">
                  <i className="fa-regular fa-cart-shopping" />
                </div>
              </a>
              <a href="javascript:void(0);" className="rts-btn btn-primary ml--20"><i className="fa-light fa-heart" /></a>
            </div>
            <div className="product-uniques">
              <span className="sku product-unipue"><span>SKU: </span> BO1D0MX8SJ</span>
              <span className="catagorys product-unipue"><span>Categories: </span> T-Shirts, Tops, Mens</span>
              <span className="tags product-unipue"><span>Tags: </span> fashion, t-shirts, Men</span>
            </div>
            <div className="share-social">
              <span>Share:</span>
              <a className="platform" href="http://facebook.com" target="_blank"><i className="fab fa-facebook-f" /></a>
              <a className="platform" href="http://twitter.com" target="_blank"><i className="fab fa-twitter" /></a>
              <a className="platform" href="http://behance.com" target="_blank"><i className="fab fa-behance" /></a>
              <a className="platform" href="http://youtube.com" target="_blank"><i className="fab fa-youtube" /></a>
              <a className="platform" href="http://linkedin.com" target="_blank"><i className="fab fa-linkedin" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* successfully add in wishlist */}
  <div className="successfully-addedin-wishlist">
    <div className="d-flex" style={{alignItems: 'center', gap: 15}}>
      <i className="fa-regular fa-check" />
      <p>Your item has already added in wishlist successfully</p>
    </div>
  </div>
  {/* successfully add in wishlist end */}
  {/* Modal */}
  <div className="modal modal-compare-area-start fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Products Compare</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="compare-main-wrapper-body">
            <div className="single-compare-elements name">Preview</div>
            <div className="single-compare-elements">
              <div className="thumbnail-preview">
                <img src="assets/images/grocery/01.jpg" alt="grocery" />
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="thumbnail-preview">
                <img src="assets/images/grocery/02.jpg" alt="grocery" />
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="thumbnail-preview">
                <img src="assets/images/grocery/03.jpg" alt="grocery" />
              </div>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname spacifiq">
            <div className="single-compare-elements name">Name</div>
            <div className="single-compare-elements">
              <p>J.Crew Mercantile Women's Short</p>
            </div>
            <div className="single-compare-elements">
              <p>Amazon Essentials Women's Tanks</p>
            </div>
            <div className="single-compare-elements">
              <p>Amazon Brand - Daily Ritual Wom</p>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Price</div>
            <div className="single-compare-elements price">
              <p>$25.00</p>
            </div>
            <div className="single-compare-elements price">
              <p>$39.25</p>
            </div>
            <div className="single-compare-elements price">
              <p>$12.00</p>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Description</div>
            <div className="single-compare-elements discription">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
            </div>
            <div className="single-compare-elements discription">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
            </div>
            <div className="single-compare-elements discription">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Rating</div>
            <div className="single-compare-elements">
              <div className="rating">
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <span>(25)</span>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="rating">
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <span>(19)</span>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="rating">
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <i className="fa-solid fa-star" />
                <span>(120)</span>
              </div>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Weight</div>
            <div className="single-compare-elements">
              <div className="rating">
                <p>320 gram</p>
              </div>
            </div>
            <div className="single-compare-elements">
              <p>370 gram</p>
            </div>
            <div className="single-compare-elements">
              <p>380 gram</p>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Stock status</div>
            <div className="single-compare-elements">
              <div className="instocks">
                <span>In Stock</span>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="outstocks">
                <span className="out-stock">Out Of Stock</span>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="instocks">
                <span>In Stock</span>
              </div>
            </div>
          </div>
          <div className="compare-main-wrapper-body productname">
            <div className="single-compare-elements name">Buy Now</div>
            <div className="single-compare-elements">
              <div className="cart-counter-action">
                <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                  <div className="btn-text">
                    Add To Cart
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                </a>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="cart-counter-action">
                <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                  <div className="btn-text">
                    Add To Cart
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                </a>
              </div>
            </div>
            <div className="single-compare-elements">
              <div className="cart-counter-action">
                <a href="#" className="rts-btn btn-primary radious-sm with-icon">
                  <div className="btn-text">
                    Add To Cart
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                  <div className="arrow-icon">
                    <i className="fa-regular fa-cart-shopping" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*================= Preloader Section Start Here =================*/}
  <div id="weiboo-load">
    <div className="preloader-new">
      <svg className="cart_preloader" role="img" aria-label="Shopping cart_preloader line animation" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={8}>
          <g className="cart__track" stroke="hsla(0,10%,10%,0.1)">
            <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
            <circle cx={43} cy={111} r={13} />
            <circle cx={102} cy={111} r={13} />
          </g>
          <g className="cart__lines" stroke="currentColor">
            <polyline className="cart__top" points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" strokeDasharray="338 338" strokeDashoffset={-338} />
            <g className="cart__wheel1" transform="rotate(-90,43,111)">
              <circle className="cart__wheel-stroke" cx={43} cy={111} r={13} strokeDasharray="81.68 81.68" strokeDashoffset="81.68" />
            </g>
            <g className="cart__wheel2" transform="rotate(90,102,111)">
              <circle className="cart__wheel-stroke" cx={102} cy={111} r={13} strokeDasharray="81.68 81.68" strokeDashoffset="81.68" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
  {/*================= Preloader End Here =================*/}
  <div className="search-input-area">
    <div className="container">
      <div className="search-input-inner">
        <div className="input-div">
          <input id="searchInput1" className="search-input" type="text" placeholder="Search by keyword or #" />
          <button><i className="far fa-search" /></button>
        </div>
      </div>
    </div>
    <div id="close" className="search-close-icon"><i className="far fa-times" /></div>
  </div>
  <div id="anywhere-home" className="anywere" />
  {/* progress area start */}
  <div className="progress-wrap">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{transition: 'stroke-dashoffset 10ms linear 0s', strokeDasharray: '307.919, 307.919', strokeDashoffset: '307.919'}} />
    </svg>
  </div>
  {/* progress area end */}
</div>

    );
};

export default invoice;