import { useNavigate } from 'react-router-dom';
import Http from '../../Http'
import * as action from '../../store/actions'

/* export function login(credentials) {
 
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/login`, { email: credentials.email,
                password:credentials.password, rememberMe: credentials.rememberMe}, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }

                })

        })
    )
}; */
/* 
export function verification(credentials) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com//api/users/verify`, {
                email: credentials.email,
                verificationCode: credentials.verificationCode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
}; */


// Action de vérification
export function verification(credentials) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/verify`, {
                email: credentials.email,
                verificationCode: credentials.verificationCode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    // Vérification réussie
                    dispatch({
                        type: 'VERIFICATION_SUCCESS',
                        payload: res.data
                    });              
                   return resolve(res);
                })
                .catch(function (err) {
                    // Gestion des erreurs
                    if (!err.response) {
                        // Erreur réseau
                        const data = { notNetwork: true };
                        // dispatch({
                        //     type: 'VERIFICATION_FAIL',
                        //     error: "Erreur réseau. Veuillez vérifier votre connexion."
                        // });
                        return reject(data);
                    } else {
                        const statusCode = err.response.status;
                        const errorData = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };

                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs spécifiques
                            errorData.error = err.response.data.message;
                            // dispatch({
                            //     type: 'VERIFICATION_FAIL',
                            //     error: err.response.data.message
                            // });
                        }

                        return reject(errorData);
                    }
                });
        })
    );
};



export function login(credentials,navigate) {
    
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/login`, {
                email: credentials.email,
                password: credentials.password,
                rememberMe: credentials.rememberMe
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {                
                    const message =res.data.message
                    if(message == 'Invalid credentials'){
                            const message_="votre email ou votre mot de passe est incorrect"
                            dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                            return reject({
                                error: 'Invalid credentials',
                                statusCode: 500,
                                notNetwork: false
                            });
                    }
                    // alert('ehec')
                    if(message == 'Account not verified'){
                        const message_="votre compte n est pas verifie"
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        navigate('/verify')
                             return reject({
                                error: 'Account not verified',
                                statusCode: 500,
                                notNetwork: false
                            }); 
                    }
                   
                    const usertoken = res.data;
                    const token = usertoken.token;
                    const user = usertoken.user;

                    // Stocker le token dans le localStorage
                    localStorage.setItem('authToken', token);
                    localStorage.setItem('userInfo', JSON.stringify(user));

                    // Vérifier si le token est bien dans le localStorage
                    const storedToken = localStorage.getItem('authToken');
                    const storeduserInfo = localStorage.getItem('userInfo');
                    console.log('Token stocké :', storedToken);  // Affiche le token dans la console
                    console.log('userInfo stocké :', storeduserInfo);  // Affiche une chaîne JSON de l'objet

                    // Dispatch de l'action avec les données de connexion
                    dispatch(action.authLogin(res.data));

                    // Résoudre la promesse avec la réponse
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                      /*   alert('alert echec') */
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
};


export function socialLogin(credentials) {
    let formdata = new FormData();

    formdata.append('email', credentials.email);
    formdata.append('social_provider', credentials.social_provider);
    formdata.append('social_id', credentials.social_id);
    formdata.append('social_nickname', credentials.social_nickname);
    formdata.append('social_token', credentials.social_token);
    formdata.append('social_refresh_token', credentials.social_refresh_token);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/v1/login-using-social-account`, formdata)
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};
export function registerP(data1) {
    return dispatch => (
    new Promise((resolve, reject) => {
        Http.post(`https://api.wemaza.com/api/users/register `, {
            name: data1.name,
            password: data1.password,
            email: data1.email,
            phone: data1.phone,
            location: data1.location,
            role: data1.role
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (res) {
                console.log("Bénéficiaire enregistré avec succès:", res);
                const protectorId = res.data.protector_id;
                const email = res.data.protectorEmail.email 
              
                
                console.log('email aaaaaaaaaaaaaa',email)
                console.log('protectorId aaaaaaaaaaaaaa',protectorId)

                // Sauvegarder dans le localStorage
                localStorage.setItem('protector_id', protectorId);
                localStorage.setItem('protector_email', email);
    
                // Récupérer et afficher dans la console
                const storedProtectorEmailJson = localStorage.getItem('protector_email');
                const storedProtectoridlJson = localStorage.getItem('protector_id');
                dispatch(action.setEmail(storedProtectorEmailJson));
                dispatch(action.setIdProtector(storedProtectoridlJson));
               

                console.log('protectorEmailObject ttttttttttttttttt',storedProtectorEmailJson)
                resolve(res);  // Résolution si l'enregistrement est réussi
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement du bénéficiaire:", error);
                reject(error);  // Rejet si l'action échoue
            });
    }));
}
/* export function register(data1, data2) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/register`, {
                name: data1.name,
                password: data1.password,
                email: data1.email,
                phone: data1.phone,
                location: data1.location,
                role: data1.role
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {

                    /* localStorage.setItem('userInfoRegister', JSON.stringify(res));
                    const storeduserInfoRegister = localStorage.getItem('userInfoRegister');
                    console.log(data2, 'dat2hasjdahskjdahkdjajksda')
                    console.log("Utilisateur protecteur enregistré:", res);
                    console.log('userInfo stocké fffffffffffffffffffffffffffffffff:', storeduserInfoRegister);  // Affiche une chaîne JSON de l'objet
 */
                    // Si l'enregistrement du protecteur est réussi, récupérer son ID
             /*        const protectorId = res.data.protector_id;
                    console.log('protectorid tttttttttttttttttttt', protectorId)

                    // Inclure l'ID du protecteur dans les données du bénéficiaire
                    const beneficiaryData = { ...data2, id_User_protector: protectorId };
                    console.log('beneficiaryData ggggggggggggggggggggg', beneficiaryData)

                    // Appeler l'action registerB pour enregistrer le bénéficiaire
                    dispatch(registerB(beneficiaryData));
                    dispatch(action.authLogin(res));
                    return resolve(res);



                })
                .catch(function (err) {
                    if (!err.response) {
                        const data = { notNetwork: true };
                        return reject(data);
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
} */
    export function register(data1, data2) {
        return async (dispatch) => {
            try {
                const response = await Http.post(`https://api.wemaza.com/api/users/register`, {
                    name: data1.name,
                    password: data1.password,
                    email: data1.email,
                    phone: data1.phone,
                    location: data1.location,
                    role: data1.role
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.status === 200 || response.status === 201) {
                    console.log("Utilisateur protecteur enregistré avec succès:", response.data);
    
                    // Récupérer l'ID du protecteur
                    const protectorId = response.data.protector_id;
                    console.log('ID du protecteur:', protectorId);
    
                    // Inclure l'ID du protecteur dans les données du bénéficiaire
                    const beneficiaryData = { ...data2, id_User_protector: protectorId };
                    console.log('Données du bénéficiaire:', beneficiaryData);
    
                    // Enregistrer le bénéficiaire avec les données modifiées
                    dispatch(registerB(beneficiaryData));
    
                    dispatch({
                        type: 'REGISTER_SUCCESS',
                        payload: response.data
                    });
    
                    // Authentification après l'enregistrement réussi
                    dispatch(action.authLogin(response.data));
    
                } else {
                    dispatch({
                        type: 'REGISTER_FAIL',
                        error: `Erreur: Statut ${response.status} - ${response.statusText}`
                    });
                }
            } catch (error) {
                console.error("Erreur lors de l'enregistrement de l'utilisateur protecteur:", error);
                dispatch({
                    type: 'REGISTER_FAIL',
                    error: error.message
                });
            }
        };
    }
    




export function registerB(data2) {
    return async (dispatch) => {
        try {
            const response = await Http.post(`https://api.wemaza.com/api/users/register`, {
                name: data2.name,
                sexe: data2.sexe,
                phone: data2.phone,
                location: data2.location,
                role: data2.role,
                id_User_protector: data2.id_User_protector
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log("Bénéficiaire enregistré avec succès:", response.data);
                dispatch({
                    type: 'REGISTER_B_SUCCESS',
                    payload: response.data
                });
            } else {
                dispatch({
                    type: 'REGISTER_B_FAIL',
                    error: `Erreur: Statut ${response.status} - ${response.statusText}`
                });
            }
        } catch (error) {
            console.error("Erreur lors de l'enregistrement du bénéficiaire:", error);
            dispatch({
                type: 'REGISTER_B_FAIL',
                error: error.message
            });
        }
    };
}


// Action pour enregistrer un bénéficiaire ou effectuer une autre opération
/* export function registerB(data2) {

    return new Promise((resolve, reject) => {
        Http.post(`https://api.wemaza.com/api/users/register `, {
            name: data2.name,
            sexe: data2.sexe,
            phone: data2.phone,
            location: data2.location,
            role: data2.role,
            id_User_protector: data2.id_User_protector
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log("Bénéficiaire enregistré avec succès:", response);
                resolve(response);  // Résolution si l'enregistrement est réussi
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement du bénéficiaire:", error);
                reject(error);  // Rejet si l'action échoue
            });
    });
} */





export function profile() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/user/profile?include=provider`)
                .then(function (res) {
                    // handle success     
                    const data = res.data.data
                    const profileD = {
                        ln: data.lastname,
                        fn: data.firstname,
                        email: data.email,
                        _ipvkd: data.provider.data.real_id,
                        ipvkds: data.country_id,
                        __ridu: data.real_id
                    }
                    dispatch(action.profile(profileD));
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function logout() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.delete(`/logout`)
                .then(function (res) {
                    // handle success             
                    dispatch(action.authLogout());
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error=
                    return reject(err);
                })

        })
    )
};

export function getAllCountries() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                })

        })
    )
};

export function searchCountry(real_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries?search=id:${real_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function getCitiesCountry(hash_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/get-all-cities/${hash_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};