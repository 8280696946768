import { blue, green } from '@mui/material/colors';
import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Offre from '../../components/Offre';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductService from '../../../services/product';
import { useDispatch } from 'react-redux';


const Pricecing = () => {
    const [checked, setChecked] = useState(false);
    const [offres, setOffers] = useState([]);
    
    const dispatch= useDispatch()

    useEffect(() => {
        allOffers();
    }, []);
    
    const allOffers = async () => {
        try {
            const result = await dispatch(ProductService.getAllOffers());
            setOffers(result);  // Assure-toi que 'result' est la donnée correcte
        } catch (e) {
            console.error('Error fetching packs:', e);
        }
    };

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(21px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    return (
        <div style={{ fontFamily: "Poppins" }}>
            <div className="container-fluid ">
                <div style={{ textAlign: "center" }}>
                    Je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}> 4€/mois</span>
                    <FormControlLabel style={{ paddingTop: 9 }}
                        control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleSwitchChange} />}
                    />
                </div>
                <div className='container-fluid d-flex flex-wrap justify-content-center mt-3 offres' style={{  }}>
                    {offres.length > 0 ? (
                        offres.map((offre) => (<Offre key={offre.id} pack={offre} checked={checked}  // Passer la valeur de checked
                                                                             setChecked={setChecked}/>))
                    ) : (
                        <p>No offre available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Pricecing;
