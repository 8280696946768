import React from 'react';

const Page404 = () => {
    return (
        <div>
            <h2>not found</h2>
        </div>
    );
};

export default Page404;