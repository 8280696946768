import { Message } from "@mui/icons-material";


const initialcardState = {
    items: [],
    subtotals: [],
    quantity: 0,
    isActive: false,
    Active: false,
    email: '',
    idProctector: '',
    beneficiary: null,
    loading: false,
    error: null,
    message: "",
    mdp: ''
};

const cartReducer = (state = initialcardState, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            // Vérifie si le produit est déjà dans le panier
            const productExists = state.items.some(item => item._id === action.payload._id);

            // Si le produit existe déjà, ne pas l'ajouter
            if (productExists) {
                return state;
            } else {
                return {
                    ...state,
                    items: [...state.items, action.payload], // Ajoute le produit dans le panier
                    quantity: state.quantity + 1 // Met à jour correctement la quantité totale
                };
            }

        case 'REMOVE_FROM_CART':
            return {
                ...state,
                items: state.items.filter(item => item._id !== action.payload), // Supprime le produit
                subtotals: state.subtotals.filter(subtotal => subtotal.productId !== action.payload),
                quantity: state.quantity - 1 // Met à jour la quantité après suppression
            };

        case 'CLEAR_CART':
            return {
                ...state,
                items: [],
                quantity: 0
            };
        case 'SET_EMAIL':
            return {
                ...state,
                email: action.payload,  // Met à jour l'email dans l'état Redux
            };
        case 'SET_MDP':
            return {
                ...state,
                mdp: action.payload,  // Met à jour l'email dans l'état Redux
            };
        case 'ACTIVER':
            return {
                ...state,
                isActive: true,
            };
        case 'DESACTIVER':
            return {
                ...state,
                isActive: false,
            };
        case 'SET_ID':
            return {
                ...state,
                idProctector: action.payload,
            };
        case 'ACTIVERPANIER':
            return {
                ...state,
                Active: true,
            };
        case 'DESACTIVERPANIER':
            return {
                ...state,
                Active: false,
            };
        case 'UPDATE_SUBTOTAL':
            const existingSubtotalIndex = state.subtotals.findIndex(
                (item) => item.productId === action.payload.productId
            );

            if (existingSubtotalIndex !== -1) {
                return {
                    ...state,
                    subtotals: state.subtotals.map((item, index) =>
                        index === existingSubtotalIndex
                            ? { ...item, subtotal: action.payload.subtotal }
                            : item
                    ),
                };
            } else {
                return {
                    ...state,
                    subtotals: [...state.subtotals, action.payload],
                };
            }
        case 'REGISTER_B_SUCCESS':
            return {
                ...state,
                beneficiary: action.payload,
                loading: false,
                error: null,
            };
        case 'REGISTER_B_FAIL':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'SET_MESSAGE_INVALID_CREDENTIAL':
            return {
                ...state,
                message: action.payload,  // Met à jour l'email dans l'état Redux
            };
        case 'LOGOUT': // Nouvelle action pour déconnexion
            return initialcardState; // Réinitialise l'état à la valeur initiale


        default:
            return state;
    }
};

export default cartReducer;
