import * as ActionTypes from '../action-types'
import Http from '../../Http'

// reducers/packReducer.js



const initialState = {
    selectedPackId: null,
};

export const packReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_PACK':
            return {
                ...state,
                selectedPackId: action.payload,
            };
        default:
            return state;
    }
};
