import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Verify from '../verfiy/Verify';
import { useDispatch } from 'react-redux';
import { register } from '../../../services/auth/authService';
import { setEmail, setMdp } from '../../../store/actions';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';

const SecondPaiement = () => {
    const [isActive, setIsActive] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation();
    const currentPath = location.pathname;

    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        phone: '',
        location: '',
        role: 'protector',
    });
    const [formData_, setFormData_] = useState({
        name: '',
        sexe: '',
        id_User_protector: '',
        phone: '',
        location: '',
        role: 'beneficiary',
    });

    const handleChange_ = (e) => {
        const { name, value } = e.target;
        setFormData_({
            ...formData_,
            [name]: value,
        });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'email') {
            dispatch(setEmail(value));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData.password);
        console.log('Form data submitted:', formData_);
        try {
            dispatch(setMdp(formData.password));
            dispatch(register(formData, formData_)).then(() => { setIsActive(true) });
        } catch (e) {
          /*   alert("erreur ") */
        }
    };

    return (



        <section className="login-form container-fluid" style={{ marginBottom: 150 }}>
            {isActive ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
                    <Verify
                        currentPath={currentPath}
                     />
                </div>
            ) : null}

            <div className="container mt-4" style={{ background: 'white', marginBottom: 200 }}>
                <div className="row flex-column-reverse flex-lg-row">
                    {/* Left Section */}
                    <div className="col-12 col-lg-6 p-4">
                        <p className="bg-light p-2">Avez-vous déjà un compte ? Protecteur</p>
                        <form onSubmit={handleSubmit}>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="name" className="form-label" >Nom</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    required
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Entrez votre nom"
                                />
                            </div>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="password" className="form-label">Mot de passe</label>
                                <input
                                    style={{ height: 55 }}
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    required
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Entrez votre mot de passe"
                                />
                            </div>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="email" className="form-label">Email</label>
                                <input
                                    style={{ height: 55 }}
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Entrez votre email"
                                />
                            </div>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="phone" className="form-label">Téléphone</label>
                                <input
                                    style={{ height: 55 }}
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    required
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Entrez votre numéro de téléphone"
                                />
                            </div>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="location" className="form-label">Adresse</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    name="location"
                                    required
                                    value={formData.location}
                                    onChange={handleChange}
                                    placeholder="Entrez votre adresse"
                                />
                            </div>
                            {/* <button type="submit" className="btn btn-success">Soumettre</button> */}
                            {/*  </form> */}
                            {/*  </div>
                    <div className=" mt-4">
                        <div style={{ padding: 10 }}> */}
                            <div style={{ marginTop: 15 }}>
                                <p style={{ background: "rgba(56, 115, 184, 0.1)", padding: 10 }}>avez vous deja un compte ? Beneficiare </p>
                            </div>
                            {/* <form onSubmit={handleSubmit}> */}
                            <div  >
                                <label style={{ fontWeight: 'normal' }} htmlFor="name" className="form-label">Nom</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    required
                                    value={formData_.name}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre nom"
                                />
                            </div>
                            <div className="mb-3 d-flex">
                                <label style={{ fontWeight: "normal" }} className="form-label mr-4">Sexe</label>
                                <div className="form-check mr-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sexe"
                                        required
                                        id="sexeMale"
                                        value="male"
                                        checked={formData_.sexe === 'male'}
                                        onChange={handleChange_}
                                    />
                                    <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeMale">
                                        Homme
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sexe"
                                        required
                                        id="sexeFemale"
                                        value="female"
                                        checked={formData_.sexe === 'female'}
                                        onChange={handleChange_}
                                    />
                                    <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeFemale">
                                        Femme
                                    </label>
                                </div>
                            </div>

                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="phone" className="form-label">Téléphone</label>
                                <input
                                    style={{ height: 55 }}
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    required
                                    value={formData_.phone}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre numéro de téléphone"
                                />
                            </div>
                            <div  >
                                <label style={{ fontWeight: "normal" }} htmlFor="location" className="form-label">Adresse</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    name="location"
                                    required
                                    value={formData_.location}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre adresse"
                                />
                            </div>
                            <button type="submit" className="btn btn-success">Soumettre</button>
                        </form>
                    </div>

                    {/* Right Section */}
                    <div className="col-12 col-lg-6 p-4">
                        <h2 className="mt-5">Votre carte</h2>

                        <div className="credit-card p-3" style={{ border: '1px solid #ccc', borderRadius: 5 }}>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <img src="/assets/images/logo1.png" alt="Logo" className="avatar" />
                                <AccountCircle style={{ fontSize: 40, color: '#1976d2' }} />
                            </div>
                            <div>
                                <div className="number mb-1">1234 5678 9012 3456</div>
                                <div className="name">John Doe</div>
                                <div className="expiration">Exp: 12/24</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default SecondPaiement;
