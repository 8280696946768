import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthManager from './AuthManager';

const Offre = ({ pack, checked, setChecked }) => {

    return (
        <div style={{marginBottom:100}}>
            {pack.tier === "Medium" ?





                <div>
                    <div className="d-flex flex-column justify-content-center offreWidth m-2" style={{ position: "relative", color: "#1d1d1b", borderColor: "", border: "1px solid #38b873", padding: "24px", textAlign: "center", Width: 310, height: "auto",   fontWeight: "bold" }}>
                        <div style={{ position: "absolute", left: "23%", top: "-9px", background: "#38b873", color: "#fff", paddingLeft: 4, paddingRight: 4, fontSize: 12 }}>   recommandé pour vous </div>
                        <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6, color: "#38b873" }}>{pack.title + " " + pack.tier}</div>
                        {
                            checked ? <>
                                <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}><span style={{ textDecoration: "line-through" }}>{pack.price.monthly}€</span>{pack.price.annual}€/mois</div>
                                <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>pendant 12 mois, 0,99€ le premier mois</div>
                            </>

                                : <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>{pack.price.monthly}€/mois</div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                                </>

                        } 
                        <div>
                            <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>

                            </ul>
                        </div>
                        <div >
                        <AuthManager>
                                {(isLoggedIn, handleLogout)=>(

                                

                                 <div>  {
                                    isLoggedIn?( <Link to={'/intermediary'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>):
                                    ( <Link to={'/intermediary'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>)
                                    
                                    } 
                                </div>
                            )}
                            </AuthManager>
                        </div>
                        {/* <div className='d-flex flex-row align-items-center'>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                            <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        </div> */}
                       {/*  <div style={{ height: 40, cursor: "pointer" }}>
                            <Link to={'/connected-protector-beneficiary-account'}>   <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} /></Link>
                        </div>
                        <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                            Exclusivité Google: -50% la première année
                        </div> */}


                    </div>
                </div>

                :


                <>
                    <div className="d-flex flex-column justify-content-center mb-5 offreWidth m-2" style={{ background: "rgba(56, 184, 115, 0.1)", color: "#1d1d1b", borderColor: "#38b873", border: "1px solid #38b873", padding: "24px", textAlign: "center", Width: 310, height: "auto",   fontWeight: "bold" }}>
                        <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6 }}>{pack.title + " " + pack.tier}</div>

                        {
                            checked ? (
                                <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>
                                        <span style={{ textDecoration: "line-through" }}>{pack.price.monthly}€</span> {pack.price.annual}€/mois
                                    </div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>
                                        pendant 12 mois, 0,99€ le premier mois
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>{pack.price.monthly}€/mois</div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>
                                        0,99€ le premier mois
                                    </div>
                                </>
                            )
                        }

                        <div>
                            <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                            </ul>
                        </div>

                        <div>
                        <AuthManager>
                                {(isLoggedIn, handleLogout)=>(

                                

                                 <div>  {
                                    isLoggedIn?( <Link to={'/intermediary'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>):
                                    ( <Link to={'/intermediary'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>)
                                    
                                    } 
                                </div>
                            )}
                            </AuthManager>
                           {/*  <AuthManager>
                                {(isLoggedIn, handleLogout)=>(

                                

                                 <div>  {
                                    isLoggedIn?( <Link to={'/connected-protector-beneficiary-account'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>):
                                    ( <Link to={'/linked-protector-beneficiary-account'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>)
                                    
                                    } 
                                </div>
                            )}
                            </AuthManager> */}
                        </div>

                     {/*    <div className='d-flex flex-row align-items-center'>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                            <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        </div> */}

                        {/* <div style={{ height: 40, cursor: "pointer" }}>
                            <Link to={'/connected-protector-beneficiary-account'}>
                                <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} />
                            </Link>
                        </div> */}

                        {/* <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                            Exclusivité Google: -50% la première année
                        </div> */}
                    </div>
                    <div>
                    </div></>


            }
        </div>



    );
};

export default Offre;
