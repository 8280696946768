import React, { useState } from 'react';
import { registerB } from '../../../services/auth/authService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProfilBeneficiaire from '../../components/ProfilBeneficiaire';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';

const BenficiaryPaiment = () => { 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const storeduserInfo = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(storeduserInfo);
    const idprotector = userInfo.protectorSpecificField.protector_id;
    console.log(idprotector, 'sdfsdfsfdsdfsdf')


    const [formData_, setFormData_] = useState({
        name: '',
        sexe: '',
        id_User_protector: idprotector,
        phone: '',
        location: '',
        role: 'beneficiary',
    });

    const handleChange_ = (e) => {
        const { name, value } = e.target;
        setFormData_({
            ...formData_,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Début de handleSubmit");

        // Logique de soumission
        console.log('Formulaire soumis', formData_);
        try {
            console.log("Avant dispatch");
            const result = await dispatch(registerB(formData_));
            console.log('Bénéficiaire créé avec succès:', result);
            /* alert('Bénéficiaire créé avec succès'); */
            console.log("Navigation vers /paiement");
            navigate('/paiement');
        } catch (error) {
            console.error('Erreur lors de la création du bénéficiaire :', error);
           /*  alert('Erreur lors de la création du bénéficiaire'); */
        }
    }


    return (
        <div style={{ marginBottom: 150 }}><div style={{ marginBottom: 50 }}>

            <div className='container-fluid d-flex flex-wrap justify-content-around'>
                <div className="col-12 col-md-6 mt-4">
                    <div style={{ padding: '0 20px 20px 20px' }}>
                        <div style={{ marginTop: 15 }}>
                            <p style={{ background: "rgba(56, 115, 184, 0.1)", padding: 10 }}>Avez-vous déjà un compte ? Bénéficiaire</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label style={{ fontWeight: 'normal' }} htmlFor="name" className="form-label">Nom</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    required
                                    value={formData_.name}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre nom"
                                />
                            </div>

                            <div className="mb-3 d-flex flex-wrap">
                                <label style={{ fontWeight: "normal", marginRight: '10px' }} className="form-label">Sexe</label>
                                <div className="form-check mr-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sexe"
                                        required
                                        id="sexeMale"
                                        value="male"
                                        checked={formData_.sexe === 'male'}
                                        onChange={handleChange_}
                                    />
                                    <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeMale">Homme</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sexe"
                                        required
                                        id="sexeFemale"
                                        value="female"
                                        checked={formData_.sexe === 'female'}
                                        onChange={handleChange_}
                                    />
                                    <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeFemale">Femme</label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label style={{ fontWeight: "normal" }} htmlFor="phone" className="form-label">Téléphone</label>
                                <input
                                    style={{ height: 55 }}
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    required
                                    value={formData_.phone}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre numéro de téléphone"
                                />
                            </div>

                            <div className="mb-3">
                                <label style={{ fontWeight: "normal" }} htmlFor="location" className="form-label">Adresse</label>
                                <input
                                    style={{ height: 55 }}
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    name="location"
                                    required
                                    value={formData_.location}
                                    onChange={handleChange_}
                                    placeholder="Entrez votre adresse"
                                />
                            </div>

                            <button type="submit" className="btn btn-success w-100">Soumettre</button>
                        </form>
                    </div>
                </div>

                <div className="col-12 col-md-5 mt-4">
                    <h2 className='mt-5'>Votre carte</h2>
                    <div className="credit-card p-3" style={{ border: '1px solid #ccc', borderRadius: 10 }}>
                        <div className='d-flex justify-content-between'>
                            <img src="/assets/images/logo1.png" alt="Logo" className='avatar' style={{ width: 50 }} />
                            <IconButton>
                                <AccountCircle style={{ fontSize: 40, color: '#1976d2' }} />
                            </IconButton>
                        </div>
                        <div style={{ paddingLeft: 12 }}>
                            <div className="number mb-1">1234 5678 9012 3456</div>
                            <div className="name">John Doe</div>
                            <div className="expiration">Exp: 12/24</div>
                        </div>
                    </div>
                </div>
            </div>




        </div >
            <div className="m-5" style={containerStyle}>
                <p style={titleStyle}>Sélectionner l'un de vos bénéficiaires</p>
                <div style={flexStyle}>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                    <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
                </div>
                <style>{`${responsiveStyles}`}</style>
            </div>

        </div>
    );
};

export default BenficiaryPaiment;

const Responsive = {
    '@media (width < 1035px)': {
        'reponsivetest': {
            flexDirection: 'column'
        }
    },
}

const containerStyle = {
    textAlign: "center",
    background: "transparent",
    height: "auto",
    border: "2px solid #E0E0E0",
    padding: 20,
    borderRadius: 20
};

const titleStyle = {
    fontSize: "25px"
};

const flexStyle = {
    marginTop: 20,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    /* justifyContent: "center", */

    marginLeft: 20
};

const profilBeneficiaireStyle = {
    /*  flex: "1 1 0px", // Responsive behavior
     margin: "10px" */
};

const responsiveStyles = {
    '@media (max-width: 1200px)': {
        '.d-flex': {
            justifyContent: "space-around"
        }
    },
    '@media (max-width: 768px)': {
        '.d-flex': {
            flexDirection: "row"
        },
        '.m-5': {
            margin: "20px"
        }
    },
    '@media (max-width: 576px)': {
        '.d-flex': {
            flexDirection: "column"
        },
        'p': {
            fontSize: "20px"
        }
    }
};