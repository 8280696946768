

const initialcardState = {
    items: []
};

const wishlistReducer  = (state = initialcardState, action) => {
    switch (action.type) {
        case 'ADD_TO_WISHLIST':
            return {
                ...state,
                items: [...state.items, action.payload] // Ajoute le produit
            };
        case 'REMOVE_FROM_WISHLIST':
            return {
                ...state,
                items: state.items.filter(item => item._id !== action.payload) // Supprime le produit par id
            };
        default:
            return state;
    }
};

export default wishlistReducer;