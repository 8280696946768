import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrementQuantity, removeFromCart, updateSubtotalForProduct } from '../../store/actions';

const CardCart = ({ product }) => {
  const [Q, setQ] = useState(1); 
  const [subtotal, setSubt] = useState(0);

  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
      dispatch(removeFromCart(product.id));
  };

  const handlesubtotal = () => {
      dispatch(updateSubtotalForProduct(product.id, subtotal));
  };

  const addQ = () => {
      setQ(prevQ => (prevQ < product.quantity ? prevQ + 1 : prevQ));
  };

  const reduceQ = () => {
      setQ(prevQ => (prevQ > 0 ? prevQ - 1 : prevQ));
  };

  const cal = () => {
      const newSubtotal = product.price * Q;
      setSubt(newSubtotal);
  };

  useEffect(() => {
      cal();
  }, [Q]);

  useEffect(() => {
      handlesubtotal();
  }, [subtotal]);

  const subtotals = useSelector((state) => state.__ct.subtotals);
  const productSubtotal = subtotals.find((item) => item.productId === product.id)?.subtotal || subtotal;

  return (
      <div className="single-cart-area-list main item-parent">
          <div className="product-main-cart">
              <div className="close section-activation" onClick={handleRemoveFromCart}>
                  <i className="fa-regular fa-x" />
              </div>
              <div className="thumbnail">
                  <img src={product.image} alt="shop" />
              </div>
              <div className="information">
                  <Link to={`/8/${product.id}`} key={product.real_id}>
                      <h6 className="title">{product.name}</h6>
                  </Link>
              </div>
          </div>
          <div className="price">
              <p>${product.price}</p>
          </div>
          <div className="quantity">
              <div className="quantity-edit">
                  <input type="text" className="input" value={Q} readOnly />
                  <div className="button-wrapper-action">
                      <button className="button" onClick={reduceQ}>
                          <i className="fa-regular fa-chevron-down" />
                      </button>
                      <button className="button plus" onClick={addQ}>
                          +<i className="fa-regular fa-chevron-up" />
                      </button>
                  </div>
              </div>
          </div>
          <div className="subtotal">
              <p>$ {subtotal}</p>
          </div>
      </div>
  );
};


export default CardCart;
