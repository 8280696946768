import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const ProfilBeneficiaire = () => {
    return (
       
                <Link to={'/paiement'} style={{
            background: "transparent", 
            display: "flex", 
            alignItems: "center", 
            padding: "1px 40px", 
            borderRadius: "8px",
            border: "2px solid #A5ADB4FF",
            maxWidth: "300px",
            height: "70px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            justifyContent: "space-between",
            marginTop:20,
            margin:20,
            background: "#E0E0E0"
        }}>
            <IconButton>
                <AccountCircle style={{ fontSize: 50, color: '#1976d2' }} />
            </IconButton>
            <div style={{
                marginLeft: "16px",
                fontSize: "16px", 
                fontWeight: "bold", 
                color: "#333"
            }}>
                Jean Loic Franc<br />
                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#555" }}>Tel: 69999999</span>
            </div>
        </Link>
       
    );
};

export default ProfilBeneficiaire;