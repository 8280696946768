import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Cela fait défiler la page vers le haut
  }, [location]); // Le hook se déclenche chaque fois que la localisation change

  return null;
}

export default ScrollToTop;
