import * as React from 'react';

import styles from './register.module.css';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Await, Link, Navigate, useNavigate } from 'react-router-dom';

import ProfilBeneficiaire from '../../components/ProfilBeneficiaire';
import { registerB } from '../../../services/auth/authService';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';



// --- Même QontoConnector et ColorlibConnector que dans votre code ---

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

// Les étapes du formulaire
const steps = ['Inscription bénéficiaire'];

export default function Register_() {


  const dispatch = useDispatch()



  // Gestion de changement pour mettre à jour formData
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

  };
  /* if (name === 'email') {
         dispatch(setEmail(value)); 
     } */
  /* const email_ = useSelector((state) => state.__ct.email)
  localStorage.setItem('emailRedux', email_);
  const storedEmail = localStorage.getItem('emailRedux');
  console.log('ttttttttttttt,',storedEmail) */





  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*  const handleChange = (step, field, value) => {
       setFormData((prev) => ({
           ...prev,
           [step]: {
               ...prev[step],
               [field]: value,
           },
       }));
   };
*/
  const navigate = useNavigate()
  const storeduserInfo = localStorage.getItem('userInfo');
  const userInfo = JSON.parse(storeduserInfo);
  const idprotector = userInfo.protectorSpecificField.protector_id;
  console.log(idprotector, 'sdfsdfsfdsdfsdf')


  const [formData, setFormData] = useState({
    id_User_protector: idprotector,
    name: '',
    sexe: '',
    phone: '',
    location: '',
    role: 'beneficiary'
  });
  const isFormValid = () => {
    return formData.name && formData.phone && formData.location && formData.sexe;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Début de handleSubmit");
    if (isFormValid()) {
      // Logique de soumission
      console.log('Formulaire soumis', formData);
      try {
        console.log("Avant dispatch");
        const result = await dispatch(registerB(formData));
        console.log('Bénéficiaire créé avec succès:', result);
        /* alert('Bénéficiaire créé avec succès'); */
        console.log("Navigation vers /paiement");
        navigate('/paiement');
      } catch (error) {
        console.error('Erreur lors de la création du bénéficiaire :', error);
      /*   alert('Erreur lors de la création du bénéficiaire'); */
      }
    }

    console.log("Fin de handleSubmit");
  };




  /* const renderBeneficiaryForm = () => (
      <div className='beneficiary-form'>
          <Grid container spacing={2} className='beneficiary-form d-flex flex-column'style={{width: "calc(100% + 400px)"}}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                      label="Nom"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }} // largeur fixe de base
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                      label="Numéro"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }} // largeur fixe de base
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                  <TextField
                      label="Localisation"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }} // largeur fixe de base
                  />
              </Grid>
              <Grid item xs={12}>
                  <FormControl component="fieldset" margin="normal">
                      <FormLabel component="legend">Sexe</FormLabel>
                      <RadioGroup
                          aria-label="gender"
                          name="sexe"
                          value={formData.sexe}
                          onChange={handleChange}
                          row
                      >
                          <FormControlLabel value="male" control={<Radio />} label="Homme" />
                          <FormControlLabel value="female" control={<Radio />} label="Femme" />
                          <FormControlLabel value="other" control={<Radio />} label="Autre" />
                      </RadioGroup>
                  </FormControl>
              </Grid>
          </Grid>
      </div>
  ); */

  const renderBeneficiaryForm = () => (
    <div className=''>
      <div /* style={{ width: 700, marginRight: 300 }} */>
        <TextField
          label="Nom"
          variant="outlined"
          fullWidth
          margin="normal"
          name="name" // Ajout de l'attribut name
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label="Numéro"
          variant="outlined"
          fullWidth
          margin="normal"
          name="phone" // Correction du name, devrait correspondre à 'phone'
          value={formData.phone}
          onChange={handleChange}
        />
        <TextField
          label="Localisation"
          variant="outlined"
          fullWidth
          margin="normal"
          name="location" // Ajout de l'attribut name
          value={formData.location}
          onChange={handleChange}
        />

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Sexe</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="sexe" // Correction du name, devrait correspondre à 'sexe'
            value={formData.sexe}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="male" control={<Radio />} label="Homme" />
            <FormControlLabel value="female" control={<Radio />} label="Femme" />
            <FormControlLabel value="other" control={<Radio />} label="Autre" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );




  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return renderBeneficiaryForm();
      default:
        return 'Étape inconnue';
    }
  };

  return (

    <section className="login-form d-flex align-items-center flex-column " style={{ marginBottom: 150 }}>

      <div className='d-flex container-fluid flex-wrap align-items-center justify-content-center userconnect' /* style={{ marginTop: 50 ,marginLeft:40 }} */>
        <div className='stepper-section' style={{ flexGrow: 1, minWidth: '300px',marginBottom:35 }}>
          <Stack /* sx={{ width: '100%' }} */ spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h3>Formulaire terminé !</h3>
                  <Link to={'/paiement'}>
                    <button className='btn btn-primary m-3'>Continuez pour effectuer le paiement</button>
                  </Link>
                </div>
              ) : (
                <div style={{ width: '100%', maxWidth: 700, margin: '0 auto' }}>
                  {renderStepContent(activeStep)}
                  <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 2, mr: 2 }}>
                    Retour
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Link to={'/paiement'}>
                      <button className='btn btn-primary m-3' disabled={!isFormValid()} onClick={handleSubmit}>
                        TERMINER
                      </button>
                    </Link>
                  ) : (
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 2 }}>
                      Suivant
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Stack>
        </div>
        <div className='register-card-section'style={{ marginTop:-70 }} >
          <div className='Register__card' >
            <div className={styles.sectiondiv}>
              <div className='p-5'>
                <div className={styles.containerCredit}>
                  <div className={`${styles.card} ${styles.frontface}`}>
                    <div>
                      <span style={{ background: "transparent" }}>
                        <p className="titrecredit">Wemaza</p>
                      </span>
                    </div>
                    <div className={styles.carddetails}>
                      <div className={styles.namenumber}>
                        <p className={styles.CardNumber}>Card Number</p>
                        <p className={`${styles.number} ${styles.titrecredit}`}>8050 2030 3020 5040</p>
                        <p className={`${styles.name} ${styles.titrecredit}`}>Prem Kumar Shahi</p>
                      </div>
                      <div className={styles.validdate}>
                        <p className={styles.CardNumber}>Valid Thru</p>
                        <p className={styles.titrecredit}>05/28</p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.card} ${styles.backface}`} style={{ marginTop: -50 }}>
                    <p className={styles.CardNumber}>
                      For customer service call +977 4343 3433 or email at mastercard@gmail.com
                    </p>
                    <span className={styles.magneticstrip} style={{ marginTop: -50, backgroundColor: "black" }} />
                    <div className={styles.signature} style={{ marginTop: 0 }}><i>005</i></div>
                    <p className={styles.titrecredit}>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia maiores sed doloremque nesciunt neque beatae voluptatibus doloribus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>












      <div className="m-5" style={containerStyle}>
        <p style={titleStyle}>Sélectionner l'un de vos bénéficiaires</p>
        <div style={flexStyle}>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
          <div style={profilBeneficiaireStyle}><ProfilBeneficiaire /></div>
        </div>
        <style>{`
        ${responsiveStyles}
      `}</style>
      </div>
    </section>
  );
}
const containerStyle = {
  textAlign: "center",
  background: "transparent",
  height: "auto",
  border: "2px solid #E0E0E0",
  padding: 20,
  borderRadius: 20
};

const titleStyle = {
  fontSize: "25px"
};

const flexStyle = {
  marginTop: 20,
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: 'center',
  marginLeft: 20
};

const profilBeneficiaireStyle = {
  flex: "1 1 0px", // Responsive behavior
  margin: "10px"
};

const responsiveStyles = {
  '@media (max-width: 1200px)': {
    '.d-flex': {
      justifyContent: "space-around"
    }
  },
  '@media (max-width: 768px)': {
    '.d-flex': {
      flexDirection: "row"
    },
    '.m-5': {
      margin: "20px"
    }
  },
  '@media (max-width: 576px)': {
    '.d-flex': {
      flexDirection: "column"
    },
    'p': {
      fontSize: "20px"
    }
  }
};