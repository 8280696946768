import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SousCardcart from '../../components/SousCardcart';
import CategoryMenu from '../../components/CategoryMenu';
import CategoriesPage from '../../components/CategoriesPage';
import axios from 'axios';
import CategoryList from '../../components/CategoryList';
import { useDispatch, useSelector } from 'react-redux';
import ProductService from '../../../services/product';
import AuthManager from '../../components/AuthManager';
import { faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import { activer, desactiver, desactiverpanier } from '../../../store/actions';



const Header = () => {

    const cartItems = useSelector((state) => state.__ct.quantity)
    const isActiveState = useSelector((state) => state.__ct.isActive)

    const dispacth = useDispatch();


    const handleclick = () => { 
        isActiveState ? 
        dispacth(desactiver())
        :
        dispacth(activer())
    }

    const active = useSelector((state) => state.__ct.Active)

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="header-top header-top1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12 d-none d-sm-block">
                            <div className="header-left d-table-cell align-middle">
                                <div className="phone-icon d-inline-block">
                                    <i className="fa-solid fa-phone-volume" />
                                </div>
                                <p className="free-consultation-text">
                                    Besoin d'aide ? Contactez-nous gratuitement :
                                    <a href="tel:+123456789" className="text-decoration-none">0800 123 45 67 890</a>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="header-right">
                                <ul className="list-unstyled d-flex justify-content-center justify-content-md-end align-items-center">
                                    <li className="d-inline-block">
                                        <i className="fa-sharp fa-solid fa-envelope" />
                                        <a className="d-inline-block email-span text-decoration-none" href="mailto:info@sencare.com">info@sencare.com</a>
                                    </li>
                                    <li className="d-inline-block ml-3">
                                        <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#4267B2' }} />
                                    </li>
                                    <li className="d-inline-block ml-4">
                                        <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25D366' }} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <header className="header paddingheader" style={{ background: "white" }}>
                <div className="main-header" style={{ backgroundColor: "white" }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                            <Link className="navbar-brand mr-0" to={"/"}>
                                <img src="/assets/images/logo1.png" alt="Logo" className="imglogo" width={"100%"} />
                            </Link>


                            <button
                                className={`navbar-toggler ${isMenuOpen ? '' : 'collapsed'}`}
                                type="button"
                                aria-controls="navbarSupportedContent"
                                aria-expanded={isMenuOpen ? 'true' : 'false'}
                                aria-label="Toggle navigation"
                                onClick={toggleMenu}
                            >
                                <span className="navbar-toggler-icon navbar-toggler-icon2" />
                                <span className="navbar-toggler-icon navbar-toggler-icon2" />
                                <span className="navbar-toggler-icon navbar-toggler-icon2" />
                            </button>
                            <div
                                className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item sancare-li-color active">
                                        <Link to={'/'}
                                            className="nav-link active"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Accueil
                                        </Link>
                                    </li>
                                    
                                   
                                    <li className="nav-item dropdown sancare-li-color">
                                        <div className="dropdown-menu sancare-drop-down sencare-drop-down2">
                                            <ul className="list-unstyled">
                                                <li className="nav-item">
                                                    <a className="dropdown-item nav-link" href="#">
                                                        Comming Soon
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="dropdown-item nav-link" href="#">
                                                        404 Page
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <AuthManager>
                                        {(isLoggedIn, handleLogout) => (
                                            <ul className="navbar-nav ml-auto">
                                                {isLoggedIn ? (
                                                    <ul>
                                                        <li className="nav-item list-unstyled btn-talk nav-btn2">
                                                            <Link
                                                                to={'/'}
                                                                className="nav-link"
                                                                onClick={handleLogout}
                                                            >
                                                                Déconnexion
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <div
                                                                style={{ display: 'inline-block', marginRight: '20px' }}
                                                            >
                                                                {active !== false ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faShoppingCart}
                                                                        size="2x"
                                                                        style={{ color: '#4CAF50', cursor:'pointer' }}
                                                                        onClick={handleclick}
                                                                    />
                                                                ) : null}
                                                                {cartItems > 0 && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '12px',
                                                                            color: 'red',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                       {cartItems}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        <li className="nav-item list-unstyled btn-talk nav-btn2">
                                                            <Link to={'/login'} className="nav-link">
                                                                Connexion
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <div
                                                                style={{ display: 'inline-block', marginRight: '20px' }}
                                                            >
                                                                {active !== false ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faShoppingCart}
                                                                        size="2x"
                                                                        style={{ color: '#4CAF50',cursor:'pointer' }}
                                                                        onClick={handleclick}
                                                                    />
                                                                ) : null}
                                                                {cartItems > 0 && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '12px',
                                                                            color: 'red',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {cartItems}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )}
                                            </ul>
                                        )}
                                    </AuthManager>
                                </ul>
                            </div>

                        </nav>
                    </div>
                </div >
            </header >
        </>
    );
};

export default Header;