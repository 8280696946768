import React from 'react';
//import './styleSheets/App.css';
//import './styleSheets/style.css';


import MesRoutes from './MesRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';

//import RouteShop from './routes/RouteShop';


function App() {
  return (
    <>  
       
       <MesRoutes/>
    
       
    </>
  );
}

export default App;

