import Http from '../../Http'
import * as action from '../../store/actions'

export function createProducts(data) {
    let formdata = new FormData();
    const files = data.document_files;
    
    formdata.append('name', data.name);
    formdata.append('quantity', data.quantity);
    formdata.append('price', data.price);
    formdata.append('category_id', data.category_id);
    formdata.append('creator_id', data.creator_id);
    formdata.append('description', data.description);
    formdata.append('is_publish', data.is_publish);
    formdata.append('is_delivrable', data.is_delivrable);
    formdata.append('product_file', data.product_file[0].file);
    formdata.append('shop_id', data.shop_id);
    // for (var i = 0; i < files.length; i++) {
    //     formdata.append('document_files[]', files[i].file);
    // }

    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.post(`/products`, formdata)
            .then(function (res) {
                // handle success           
                return resolve(res);
              })
              .catch(function (err) {
                return reject(err);
              })
                
        })
    )
};

export function updateProducts(hash_id, data) {
    let formdata = new FormData();
    const files = data.document_files;
    
    formdata.append('name', data.name);
    formdata.append('quantity', data.quantity);
    formdata.append('price', data.price);
    formdata.append('category_id', data.category_id);
    formdata.append('creator_id', data.creator_id);
    formdata.append('description', data.description);
    formdata.append('is_publish', data.is_publish);
    formdata.append('is_delivrable', data.is_delivrable);
    formdata.append('product_file', data.product_file[0].file);
    formdata.append('shop_id', data.shop_id);
    // dataZone is and object 
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.patch(`/products/${hash_id}`, formdata)
            .then(function (res) {
                // handle success     
                const data = res.data.data
                // dispatch(action.profile(profileD));
                return resolve(data);
              })
              .catch(function (err) {
                return reject(err);
              })
                
        })
    )
};

export function deleteProducts(hash_id) {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.delete(`/products/${hash_id}`)
            .then(function (res) {
                // handle success     
                const data = res.data.data
                // dispatch(action.profile(profileD));
                return resolve(data);
              })
              .catch(function (err) { 
                return reject(err);
              })
                
        })
    )
};

export function getAllPacks() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get("https://api.wemaza.com/api/packs")
            .then(function (res) { 
                const data = res.data
                console.log('gggg ',data);
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};



export function getAllOffers() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get("https://api.wemaza.com/api/offres")
            .then(function (res) { 
                const data = res.data
                console.log('gggg ',data);
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};

export function getAllHomeProducts() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop.provider.user.country,shop.city,annexesImages&limit=8`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};

export function getAlldiscountedProducts() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/discounted-products?include=shop.provider.user.country,shop.city,annexesImages&limit=8`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};

export function getProductsById(id) { 
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get("https://api.wemaza.com/v1/products/${id}")
            .then(function (res) { 
                const data = res
                console.log("un produit:" ,data)
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                 if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              })
                
        })
    )
};

export function getAllCategories() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/categories?limit=100`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function lastNewProduct() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/get-last-new-products?include=shop.provider.user.country,shop.city,annexesImages`)
            .then(function (res) {
                // handle success     
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) { 
                return reject(err);
              })
                
        })
    )
};

export function categoryFilterAllProducts(categories) {
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop&category_id=${categories}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function countryFilterAllProducts(country_id) {
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop&country_id=${country_id}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function cityFilterAllProducts(city_id) {
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop&city_id=${city_id}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function filterAllProducts(categories, country_id, city_id, price_min, price_max, name, limit, pageNo) {
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop.provider.user.country,annexesImages${categories.length > 0 ? '&category_id='+categories : ''}${country_id !== null ? '&country_id='+country_id : ''}${city_id !== null ? '&city_id='+city_id : ''}${price_min !== null ? '&price_min='+price_min : ''}${price_max !== null ? '&price_max='+price_max : ''}${name !== '' ? '&name='+name : ''}&limit=${limit}&page=${pageNo}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};


export function getAllProductsBycategories(url, limit, pageNo) {
    // console.log('type of categories value', categories.constructor)

    // console.log("newPathname ", url); return;

    let newPathname = url;
    
    if (url.includes('/categories')) {
         newPathname = url.replace('/categories', '');
    }

    if (url.includes('/product')) {
         newPathname = url.replace('/product', '');
    }


    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products-slug/Cameroun${newPathname}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function filterByName(name) {
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop.provider.user.country,annexesImages&country_id=33&name=${name}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function price_minFilterAllProducts(price_min) {
    const limit = 250
    // console.log('type of categories value', categories.constructor)
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get(`/products?include=shop&price_min=${price_min}`)
            .then(function (res) { 
                const data = res.data
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                const statusCode = err.response;
                const data = {
                    error: null,
                    statusCode,
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    data.error = err.response.data.message;
                }
                return reject(data);
              })
                
        })
    )
};

export function getPopularProducts() {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get("https://api.wemaza.com/v1/products-popular")
            .then(function (res) { 
                const data = res.data
                // console.log('gggg ',data);
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};
export function getTrendingProducts () {
    return dispatch => (
        new Promise((resolve, reject) => { 
            Http.get("https://api.wemaza.com/v1/products-trending")
            .then(function (res) { 
                const data = res.data
                // console.log('gggg ',data);
                return resolve(data);
              })
              .catch(function (err) {
                // handle error
                if (!err.response) {
                    // network error (server is down or no internet)
                    const data = {
                        notNetwork: true
                    };
                    return reject(data)
                } else {
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                        notNetwork: false
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                return reject(data);
                }
              }) 
        })
    )
};




// export function search(name) {
//     const limit = 250
//     return dispatch => (
//         new Promise((resolve, reject) => { 
//             Http.get(`${baseUrl}/countries?search=cities:${name}`)
//             .then(function (res) {  
//                 const data = res.data
//                 return resolve(data);
//               })
//               .catch(function (err) {
//                 // handle error
//                 const statusCode = err.response;
//                 const data = {
//                     error: null,
//                     statusCode,
//                 };
//                 if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
//                     data.error = err.response.data.message;
//                 }
//                 return reject(data);
//               })
                
//         })
//     )
// };