import * as ActionTypes from '../action-types'

export function initialStatManual(payload) {
    return {
        type: ActionTypes.INITSTATE_T,
        payload
    }
}

export function authLogin(payload) {
    return {
        type: ActionTypes.AUTH_LOGIN,
        payload
    }
}


export function authLogout() {
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authCheck() {
    return {
        type: ActionTypes.AUTH_CHECK
    }
}

export function authVerify(payload) {
    return {
        type: ActionTypes.AUTH_VERIFY,
        payload
    }
}

export function changeNavigatorTheme(payload) {
    return {
        type: ActionTypes.CHANGE_N_T,
        payload
    }
}

export function auth_t(payload) {
    return {
        type: ActionTypes.AUTH_T,
        payload
    }
}
export function profile(payload) {
    return {
        type: ActionTypes.PROFILE_T,
        payload
    }
}
export function classes(payload) {
    return {
        type: ActionTypes.CLASSES_T,
        payload
    }
}
export function dataTable(payload) {
    return {
        type: ActionTypes.DATA_T,
        payload
    }
}
export function limit(payload) {
    return {
        type: ActionTypes.LIMIT_T,
        payload
    }
}
export function pageNo(payload) {
    return {
        type: ActionTypes.PAGE_NO_T,
        payload
    }
}
export function ramassage(payload) {
    return {
        type: ActionTypes.RAMASS_TRANS,
        payload
    }
}
export function mapsRoutes(payload) {
    return {
        type: ActionTypes.MAPSROUTES,
        payload
    }
}
export function addBusColor(payload) {
    return {
        type: ActionTypes.ADDbUSCOLOR,
        payload
    }
}
export function addMapPosition(payload) {
    return {
        type: ActionTypes.ADDMAPPOSITION,
        payload
    }
}
export function shop_action(payload) {
    return {
        type: ActionTypes.SHOPACTION,
        payload
    }
}
export function prodId_t(payload) {
    return {
        type: ActionTypes.PRODID_T,
        payload
    }
}
export function cartTab_t(payload) {
    return {
        type: ActionTypes.CARTTAB_T,
        payload
    }
}
export function homeCartvalue(payload) {
    return {
        type: ActionTypes.HOMECARTPRODUCTVALUE,
        payload
    }
}
export function showCartTab_t(payload) {
    return {
        type: ActionTypes.SHOWCARTTAB_T,
        payload
    }
}
export function cartInitTab_t(payload) {
    return {
        type: ActionTypes.CARTINITTABT,
        payload
    }
}
export function cartUpdateTab_t(payload) {
    return {
        type: ActionTypes.CARUPDATETTAB_T,
        payload
    }
}
export function deleteCartTab_t(payload) {
    return {
        type: ActionTypes.DELETECARTTAB_t,
        payload
    }
}
export function home_p(payload) {
    return {
        type: ActionTypes.HOMEP,
        payload
    }
}

export function relaypointId_t(payload) {
    return {
        type: ActionTypes.RELAYPOINTID,
        payload
    }
}

export function showAlertHomeProduct(payload) {
    return {
        type: ActionTypes.SHOWALERTHOMEPRODUCT,
        payload
    }
}

export function showCarteadhesionStore(payload) {
    return {
        type: ActionTypes.showCarteadhesion,
        payload
    }
}

export function tabsCatValueFunction(payload) {
    return {
        type: ActionTypes.TABSCATVALUE,
        payload
    }
}

export function navCartStore(payload) {
    return {
        type: ActionTypes.NAVCARTSTORE,
        payload
    }
}

export function existantReceiverUser(payload) {
    return {
        type: ActionTypes.EXISTANTRECEIVERUSER,
        payload
    }
}

export function saveUserInfos(payload) {
    return {
        type: ActionTypes.SAVEUSERINFOS,
        payload
    }
}

export function onClickOnCmd(payload) {
    return {
        type: ActionTypes.ONCLICKONCMD,
        payload
    }
}

export function relayPointValue(payload) {
    return {
        type: ActionTypes.RELAYPOINTVALUE,
        payload
    }
}

export function beneficiaryLocation(payload) {
    return {
        type: ActionTypes.BENEFICIARYLOCATION,
        payload
    }
}

export function initRelayPointData(payload) {
    return {
        type: ActionTypes.INITRELAYPOINTDATA,
        payload
    }
}

export function typeOfHomeLocalization(payload) {
    return {
        type: ActionTypes.TYPEOFHOMELOCALIZATION,
        payload
    }
}

export function socialAssistanceObject(payload) {
    return {
        type: ActionTypes.SOCIALASSISTANCEOBJECT,
        payload
    }
}

export function selectedValueFunct(payload) {
    return {
        type: ActionTypes.SELECTEDVALUEFUNCT,
        payload
    }
}

export function initRelaypointId(payload) {
    return {
        type: ActionTypes.INITRELAYPOINTIDTYPE,
        payload
    }
}

export function economicAccessibility(payload) {
    return {
        type: ActionTypes.ECONOMICACCESSIBILITY,
        payload
    }
}

export function seacrchListAction(payload) {
    return {
        type: ActionTypes.SEARCHLIST,
        payload
    }
}

export const addToCart = (offre) => {
    return {
        type: 'ADD_TO_CART',
        payload: offre
    };
};
export const incrementQuantity = () => {
    return {
        type: 'INCREMENT_QUANTITY'
    };
};
export const decrementQuantity = () => {
    return {
        type: 'DECREMENT_QUANTITY'
    };
};

export const removeFromCart = (productId) => {
    return {
        type: 'REMOVE_FROM_CART',
        payload: productId
    };
};
export const addToWishlist = (offre) => {
    return {
        type: 'ADD_TO_WISHLIST',
        payload: offre
    };
};

export const removeFromwishlist = (productId) => {
    return {
        type: 'REMOVE_FROM_WISHLIST',
        payload: productId
    };
};
export const clearCart = () => {
    return {
        type: 'CLEAR_CART',
    };
};
export const updateSubtotalForProduct = (productId, subtotal) => {
    return {
        type: 'UPDATE_SUBTOTAL',
        payload: { productId, subtotal },
    };
};

// actions/packActions.js
export const setSelectedPack = (id) => ({
    type: 'SET_SELECTED_PACK',
    payload: id,
});
export const setEmail = (email) => {
    return {
        type: 'SET_EMAIL',
        payload: email,
    };
};
export const setMdp = (mdp) => {
    console.log("yysts ", mdp);
    return {
        type: 'SET_MDP',
        payload: mdp,
    };
};
export const setIdProtector = (id) => {
    return {
        type: 'SET_ID',
        payload: id,
    };
};
export const activer = () => {
    return {
        type: 'ACTIVER',
    };
};
export const activerpanier = () => {
    return {
        type: 'ACTIVERPANIER',
    };
};
export const desactiverpanier = () => {
    return {
        type: 'DESACTIVERPANIER',
    };
};


// Action pour désactiver
export const desactiver = () => {
    return {
        type: 'DESACTIVER',
    };
};

export const set_MESSAGE_INVALID_CREDENTIAL = (message) => {
    return {
        type: 'SET_MESSAGE_INVALID_CREDENTIAL',
        payload: message,
    };
};
export const logout = () => {
    return {
        type: 'LOGOUT',
    };
};

