import React from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon
import { faX } from '@fortawesome/free-solid-svg-icons';  // Import the 'faX' icon
import { removeFromCart } from '../../store/actions';


const Colone = ({ pack, checked }) => {


    const dispatch = useDispatch();

    const removeOffer = () => {
        dispatch(removeFromCart(pack._id));  // Call the removeFromCart action with the pack ID
    };
    const styles = {
        packRow: {
            backgroundColor: '#f9f9f9',
            borderBottom: '1px solid #ddd',
            transition: 'background-color 0.3s ease',
        },
        packRowHover: {
            backgroundColor: '#eaeaea',
        },
        packTitle: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        removeIcon: {
            color: 'red',
            fontSize: '1.2rem',
            transition: 'transform 0.2s ease',
        },
        removeIconHover: {
            transform: 'scale(1.1)',
        },
        packPrice: {
            textAlign: 'right',
        },
        cell: {
            padding: '15px',
        },
        tableRow: {
            borderRadius: '5px',
        }
    };

    return (
        <tr style={styles.packRow} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.packRowHover.backgroundColor}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.packRow.backgroundColor}>
        <td className='p-3' style={styles.packTitle}>
            <FontAwesomeIcon
                icon={faX}
                style={styles.removeIcon}
                onMouseEnter={(e) => e.currentTarget.style.transform = styles.removeIconHover.transform}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}
                onClick={removeOffer}
            />
            {pack.title || "Sans titre"}
        </td>
        {checked
            ? <td className='p-3' style={styles.packPrice}>{pack.price?.annual || "0"}$</td>
            : <td className='p-3' style={styles.packPrice}>{pack.price?.monthly || "0"}$</td>}
    </tr>

    );
};

export default Colone;
