import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../services/auth/authService';
import { useDispatch, useSelector } from 'react-redux';
import Verify from '../verfiy/Verify';
import { setEmail } from '../../../store/actions';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail_] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false)
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);

  const erreur = useSelector((state) => state.__ct.message)


  const storedEmail = localStorage.getItem('emailRedux');
  console.log('qqqqqqqqqqqqqqqqqqqqqqq,', storedEmail)
  /* if (storedEmail != null){setEmail_(storedEmail)} */
  useEffect(() => {
    if (storedEmail != null) {
      setEmail_(storedEmail);  // Mise à jour de l'email si storedEmail n'est pas null ou undefined
    }
  }, []);

  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };

  if (email != null) { }
  const handleSubmit = (e) => {
    e.preventDefault();

    const credentials = { email, password, rememberMe };
    try {
      dispatch(setEmail(email));
      dispatch(login(credentials, navigate)).then(() => {

        navigate('/');
        window.location.reload();
      })
    } catch (error) {
      console.error("Erreur lors de la connexion", error);



    };
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  const [isActive, setIsActive] = useState(false)
  const handleActive = () => {
    setIsActive(true)
  }


  return (
    <div style={{marginBottom:50}}>
      <section className="login-form d-flex align-items-center mb-5">
        <div className="container" style={{ marginBottom: 75 }}>
          <div className="login-form-title text-center">
            <figure className="login-page-logo"></figure>
            <h2>Bienvenue !</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="login-form-box">
                <div className="login-card">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        className="input-field form-control"
                        value={email}
                        type="email"
                        id="exampleInputEmail1"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail_(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        className="input-field form-control"
                        type={showPassword ? "text" : "password"}
                        id="exampleInputPassword1"
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="showPasswordCheckbox"
                        checked={showPassword}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="showPasswordCheckbox"
                      >
                        Afficher le contenu du mot de passe
                      </label>
                    </div>

                    {erreur === "votre compte n est pas verifie" ? (
                      <>
                        <p style={{ color: "red", textAlign: "center" }}>
                          {erreur}{" "}
                          <span
                            onClick={handleActive}
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                          >
                            Verifie ici
                          </span>
                        </p>

                        {isActive ? (
                          <div
                            style={{
                              position: "fixed",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              zIndex: 9999,
                            }}
                          >
                            <Verify />
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <p style={{ color: "red", textAlign: "center" }}>{erreur}</p>
                    )}

                    <button
                      type="submit"
                      className="btn btn-primary hover-effect w-100"
                    >
                      Se connecter
                    </button>

                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <label className="font-weight-normal mb-0">
                        <input
                          className="checkbox"
                          type="checkbox"
                          name="userRememberMe"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                        Souvenez-vous de moi
                      </label>
                      <a href="#" className="forgot-password">
                        Mot de passe perdu ?
                      </a>
                    </div>
                  </form>
                </div>

                <div className="join-now-outer text-center mt-4">
                  <Link
                    to={"/register"}
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      Nouveau sur Wemaza ?,
                    </span>
                    <span style={{ color: "var(--e-global-color-accent)" }}>
                      créez un compte
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default Login;