import React from 'react';
import "./DetailsPstyle.css"

const DetailsPartenaire = () => {
    return (
        <div>
        <div>
            
            <main>
                <h2>Pharmacie Centrale</h2>
                <div className="content">
                    <section className="partner-details">
                        <h3>Détails du Partenaire</h3>
                        <p>Nom de l'entreprise : Pharmacie Centrale</p>
                        <p>Localisation : Douala</p>
                        <p>Heures d'ouverture : 8h - 18h</p>
                        <p>Heures de fermeture : 18h</p>
                        <p>Services offerts : Vente de médicaments, Conseils pharmaceutiques</p>
                        <button>Souscrire pour ce service</button>
                    </section>
                    <section className="partner-image">
                        <img src="assets/img/pharmacie.jpeg" alt="Pharmacie Centrale" />
                    </section>
                </div>
                <section className="contact-form">
                    <h3>Contactez-nous</h3>
                    <form action="submit-contact-form.php" method="post">
                        <label htmlFor="name">Nom :</label>
                        <input type="text" id="name" name="name" required />
                        <label htmlFor="email">Email :</label>
                        <input type="email" id="email" name="email" required />
                        <label htmlFor="message">Message :</label>
                        <textarea id="message" name="message" rows={4} required defaultValue={""} />
                        <button type="submit">Envoyer</button>
                    </form>
                </section>
            </main>
            <footer>
                <p>© 2024 Votre Entreprise. Tous droits réservés.</p>
                <ul>
                    <li><a href="#">Mentions légales</a></li>
                    <li><a href="#">Politique de confidentialité</a></li>
                    <li><a href="#">Nous contacter</a></li>
                </ul>
            </footer>
        </div>

    </div>
    );
};

export default DetailsPartenaire;