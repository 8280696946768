import { blue, green } from '@mui/material/colors';
import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Offre from '../../components/Offre';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductService from '../../../services/product';
import { useDispatch, useSelector } from 'react-redux';
import OffrePersonnalise from '../../components/OffrePersonnalise';
import { activerpanier, addToCart, addToWishlist, desactiver, desactiverpanier, removeFromCart } from '../../../store/actions';
import Colone from '../../components/Colone';
import AuthManager from '../../components/AuthManager';


const Personnalise = () => {
    const [checked, setChecked] = useState(true);
    const [offres, setOffers] = useState([]);
    const [offresSante, setOffresSante] = useState([]);
    const [offresDomicile, setOffersDomicile] = useState([]);
    const [offresInformatique, setOffresInformatique] = useState([]);
    const items = useSelector((state) => state.__ct.items)
    const dispacth = useDispatch()

    if(items.length >0){
        dispacth(activerpanier())
    }
    if (items.length <1){
        dispacth(desactiver())
        dispacth(desactiverpanier())
    }




    const dispatch = useDispatch()
    



    useEffect(() => {

        
        allOffers();
        getOffresByPackinformatiqueId();
        getOffresByPackSanteId();
        getOffresByPackDomicileId();
    }, []);


    const getOffresByPackinformatiqueId = async () => {
        try {
            const response = await fetch(`https://api.wemaza.com/api/offres/pack/676ec9072010f89e0b7c29cc`);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur inconnue');
            }
            const datas = await response.json();
            setOffresInformatique(datas);
        } catch (err) {
            console.log('Erreur de connexion. Vérifiez votre réseau.');
        }
    };

    const getOffresByPackSanteId = async () => {
        try {
            const response = await fetch(`https://api.wemaza.com/api/offres/pack/676ec8a92010f89e0b7c29c8`);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur inconnue');
            }
            const datas = await response.json();
            setOffresSante(datas);
        } catch (err) {
            console.log('Erreur de connexion. Vérifiez votre réseau.');
        }
    };


    const getOffresByPackDomicileId = async () => {
        try {
            const response = await fetch(`https://api.wemaza.com/api/offres/pack/676ec8d92010f89e0b7c29ca`);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur inconnue');
            }
            const datas = await response.json();
            setOffersDomicile(datas);
        } catch (err) {
            console.log('Erreur de connexion. Vérifiez votre réseau.');
        }
    };

    const allOffers = async () => {
        try {
            const result = await dispatch(ProductService.getAllOffers());
            setOffers(result);  // Assure-toi que 'result' est la donnée correcte
        } catch (e) {
            console.error('Error fetching packs:', e);
        }
    };

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(21px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));


    const [isVisible, setIsVisible] = useState(false);

    const subTotal = items.reduce((total, pack) => {
        const price = checked ? pack.price?.annual : pack.price?.monthly;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    
    const isActive = useSelector((state) => state.__ct.isActive)
    


    return (

        <div style={{ fontFamily: "Poppins" }}>

               

          <div className="container-fluid" style={{ marginBottom: '100px', position: 'relative' }}>
                 {isActive? <div style={{ position: 'absolute', right: '30px', top: '-18px',background:'#FFF' }}>
                    <table style={{ border: '1px solid #000', minHeight: "auto", borderRadius: 10, background: "rgba(56,115,184,0.1)", borderCollapse: 'initial', width: '100%' }}>
                        <tbody>                       

                            {items.length > 0 ? items.map((pack, index) => (
                                < Colone key={index} pack={pack} checked={checked} />
                            )) : (
                                <tr>
                                    <td colSpan="2">Aucun pack disponible</td>
                                </tr>
                            )}
                           <tr>
                                <td className='p-3'>Sub Total</td>
                                <td className='p-3'>{subTotal}$</td>
                            </tr>


                            <tr style={{ textAlign: 'center' }}>
                                <td colSpan="2">
                                    

                                <AuthManager>
                                {(isLoggedIn, handleLogout)=>(

                                

                                 <div >  {
                                    isLoggedIn?( <Link to={'/intermediary'}><button className='btn btn-success' style={{ margin: '10px' }}>
                                        suivant
                                    </button></Link>):
                                    ( <Link to={'/intermediary'}>  <button className='btn btn-success' style={{ margin: '10px' }}>
                                        Suivant
                                    </button></Link>)
                                    
                                    } 
                                </div>
                            )}
                            </AuthManager>

                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>:null}
               
                

                <h2 style={{ textAlign: 'left', marginTop: '20px' }}>Pack santé</h2>
                <div style={{ textAlign: "center" }}>
                    Je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}> 4€/mois</span>
                    <FormControlLabel style={{ paddingTop: 9 }}
                        control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleSwitchChange} />}
                    />
                </div>
                <div className='container-fluid d-flex flex-wrap justify-content-center mt-3' style={{ marginBottom: 5, marginTop: 50 }}>
                    {offresSante.length > 0 ? (
                        offresSante.map((offre) => (
                            <OffrePersonnalise key={offre.id} pack={offre} checked={checked} setChecked={setChecked} />
                        ))
                    ) : (
                        <p>No offre available</p>
                    )}
                </div>

                <h2 style={{ textAlign: 'left' }}>Pack gestion du domicile</h2>
               
                <div className='container-fluid d-flex flex-wrap justify-content-center mt-3' style={{ marginBottom: 5, marginTop: 50 }}>
                    {offresDomicile.length > 0 ? (
                        offresDomicile.map((offre) => (
                            <OffrePersonnalise key={offre.id} pack={offre} checked={checked} setChecked={setChecked} />
                        ))
                    ) : (
                        <p>No offre available</p>
                    )}
                </div>
                <h2 style={{ textAlign: 'left' }}>Pack gestion du Informatique</h2>
                
                <div className='container-fluid d-flex flex-wrap justify-content-center mt-3' style={{ marginBottom: 5, marginTop: 50 }}>
                    {offresInformatique.length > 0 ? (
                        offresInformatique.map((offre) => (
                            <OffrePersonnalise key={offre.id} pack={offre} checked={checked} setChecked={setChecked} />
                        ))
                    ) : (
                        <p>No offre available</p>
                    )}
                </div>



                {/* <div style={{ textAlign: "center" }}>
                    Je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}> 4€/mois</span>
                    <FormControlLabel style={{ paddingTop: 9 }}
                        control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleSwitchChange} />}
                    />
                </div>
                <div className='container-fluid d-flex flex-wrap justify-content-center mt-3' style={{ marginBottom: 200, marginTop: 50, width:"90%" }}>
                    
                    {   
                        filteredOffresSante.length > 0 ? (
                            console.log('ttttttt',filteredOffresSante),
                            filteredOffresSante.map((offre) => (<Offre key={offre.id} pack={offre} checked={checked}  // Passer la valeur de checked
                                                                             setChecked={setChecked}/>))
                    ) : (
                        <p>No offre available    ulrich mbouna </p>
                    )}
                    
                </div> */}
            </div>
        </div>
    );
};

export default Personnalise;

