import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { login, verification } from '../../../services/auth/authService';

const Verify = (props) => {

  const [verificationCode_, setverificationCode] = useState(new Array(6).fill(''));
  const [userInfo, setUserInfo] = useState(null);
  const mdp = useSelector((state) => state.__ct.mdp)
  const email = useSelector((state) => state.__ct.email)
  console.log('email ttttttttt',email)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const location = useLocation()
  // const currentPath = location.pathname;


  //   useEffect(() => {
 
  //     console.log('currentPath yyyyyyyyyyyyyyyyyyyyyLK',currentPath)

  //  }, []); 


   
  const handleSubmit = (event) => {
    console.log('currentPath yyyyyyyyyyyyyyyyyyyyyG');

    event.preventDefault();
    const verificationCode = verificationCode_.join('');
   

    console.log('Code de vérification:', verificationCode);
    console.log('Email de l\'utilisateur:', email);

      const credentials = {email,verificationCode };
      const credential = {email,password: mdp};

      console.log('credential ooooooooooooooooooooooooooooooooooooooo', credential);

      dispatch(verification(credentials))
        .then(() => {
    console.log('currentPath yyyyyyyyyyyyyyyyyyyyyG',props.currentPath);
          loginFoction(credential);             
        })
        .catch((error) => {
          console.error("Erreur lors de la connexion", error);
          /* alert('Échec de la connexion. Veuillez vérifier votre code .'); */
          navigate('/verify')
        }); 
    };


    const loginFoction = (credential) => {
      dispatch(login(credential))
      
      .then(() => {
        console.log('currentPath yyyyyyyyyyyyyyyyyyyyy',props.currentPath)
        if (props.currentPath === "/linked-protector-beneficiary-account"){
          navigate('/paiement')
        }else{navigate('/');
          window.location.reload(); 
        }             
      })
      .catch((error) => {
        console.error("Erreur lors de la connexion", error);
/*         alert('Échec de la connexion. Veuillez vérifier votre code .'); */
        navigate('/verify')
      });

    }



    // Envoi de la requête fetch avec le code et l'email
   /*  fetch('http://192.162.69.253:5000/api/users/verify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        verificationCode
        // Ajouter l'email dans le corps de la requête
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          navigate('/login')
        }
      })
      .catch(error => {
        console.error('Erreur:', error);
        alert('Erreur lors de la vérification');
      }); */
  

  const handleInputChange = (value, index) => {
    const newCode = [...verificationCode_];
    newCode[index] = value;
    setverificationCode(newCode);
  };


  const styles = {

    verificationBox: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      width: '100%',
    },
    headerIcon: {
      fontSize: '2em',
      color: '#8bc53e',
    },
    title: {
      color: '#8bc53e',
      textAlign: 'center',
      marginBottom: '0',
      marginTop: '10px',
    },
    paragraph: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    inputGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    input: {
      width: '40px',
      height: '40px',
      textAlign: 'center',
      fontSize: '1.2em',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxSizing: 'border-box',
    },
    errorMessage: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '15px',
    },
    errorIcon: {
      marginRight: '10px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#8bc53e',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#7bb12e',
    }
  };

  return (
    <div className='d-flex justify-content-center' style={{ marginTop: 75, marginBottom: 200 }}>
      <div style={{ width: 500 }} className='d-flex align-items-center justify-content-center'>
        <div style={styles.verificationBox}>
          <div className="header">
            <i className="fas fa-envelope" style={styles.headerIcon} />
            <h1 style={styles.title}>Vérifiez votre Email</h1>
          </div>
          <p style={styles.paragraph}>
            Veuillez saisir le code de vérification envoyé à votre adresse électronique{' '}
            <span style={{ fontWeight: 'bold' }}> {email} :</span>
          </p>
          <form id="verificationForm" onSubmit={handleSubmit}>
            <div style={styles.inputGroup}>
              {verificationCode_.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  required
                  style={styles.input}
                  value={value}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                />
              ))}
            </div>
            {/* Afficher un message d'erreur ici si nécessaire */}
            <button type="submit" style={styles.button}>
              Confirmer
            </button>
          </form>
        </div>
      </div>
    </div>

  );
};

export default Verify;
