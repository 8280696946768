import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerP } from '../../../services/auth/authService';
import { Link, useNavigate } from 'react-router-dom';
import { setEmail } from '../../../store/actions';
import Verify from '../verfiy/Verify';

const RegisterForProtecteur = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
    // Fonction de gestion de la case à cocher
  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };
    // État pour les données du formulaire
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        phone: '',
        location: '',
        role: 'protector'
    });



    // Gestion de changement pour mettre à jour formData
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value  
        });
        /* if (name === 'email') {
            dispatch(setEmail(value)); 
        } */
    };
    const email_ = useSelector((state) => state.__ct.email)
    localStorage.setItem('emailRedux', email_);
    const storedEmail = localStorage.getItem('emailRedux');
    console.log('ttttttttttttt,',storedEmail)
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            
            dispatch(registerP(formData))
            
        } catch (error) {
            console.error('Erreur lors de l\'inscription', error);
        }
    };
    const [isActive, setIsActive]= useState(false)
    const handleActive =()=> {
        setIsActive(true)
    }

    return (
        <div>
            <section className="login-form sign-up-form d-flex align-items-center">

           {isActive? <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999}}>
                <Verify/>
            </div>:null} 
                <div className="container mt-5" style={{ marginBottom: 150, }}>
                    <div className="login-form-title text-center">
                        <h2>Créez votre compte GRATUIT</h2>
                    </div>
                    <div className="login-form-box">
                        <div className="login-card">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        className="input-field form-control"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="input-field form-control"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="input-field form-control"
                                        type="text"
                                        name="location"
                                        placeholder="Address"
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="input-field form-control"
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone"
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="input-field form-control"
                                        type={showPassword ? "text" : "password"} // Change le type entre "password" et "text"
                                        name="password"
                                        placeholder="Password"
                                        required
                                        onChange={handleChange}
                                       
                                    />
                                </div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showPasswordCheckbox"
                                        
                                        checked={showPassword}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="showPasswordCheckbox">
                                        Afficher le mot de passe
                                    </label>
                                </div>
                                <button type="submit" className="hover-effect btn btn-primary mb-0"onClick={handleActive}>
                                    Register Now
                                </button>
                            </form>
                        </div>
                        <div className="join-now-outer text-center">
                            <Link to={'/login'} style={{textDecoration:'none'}}><span style={{textDecoration:'underline'}}>Vous avez déjà un compte </span> ? <span style={{color:'#4CAF50'}}> Se connecter</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RegisterForProtecteur;
