import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CategoryList from './CategoryList';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [isListVisible, setIsListVisible] = useState(false); // État pour gérer la visibilité

  useEffect(() => {
    // Charger les données des catégories depuis l'API
    axios.get('https://api.wemaza.com/v1/categories?limit=100')
      .then(response => {
        const organizedCategories = organizeCategoriesByRealId(response.data.data);
        console.log('ggggggg', organizedCategories);
        setCategories(organizedCategories);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des catégories :", error);
      });
  }, []);

  const organizeCategoriesByRealId = (categories) => {
    // Créer un objet où chaque catégorie est indexée par son real_id
    const categoryMap = {};
  
    // Ajouter toutes les catégories dans le map
    categories.forEach(category => {
      categoryMap[category.real_id] = { ...category, subCategories: [] }; // Initialiser un tableau pour les sous-catégories
    });
  
    // Organiser les catégories en fonction du real_id et parent_id
    const organizedCategories = [];
    categories.forEach(category => {
      if (category.parent_id === null) {
        // Catégorie principale
        organizedCategories.push(categoryMap[category.real_id]);
      } else {
        // Ajouter en tant que sous-catégorie
        if (categoryMap[category.parent_id]) {
          categoryMap[category.parent_id].subCategories.push(categoryMap[category.real_id]);
        }
      }
    });
  
    return organizedCategories;
  };

  // Fonction pour basculer la visibilité de la liste des catégories
  const toggleCategoryListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  return (
    <div>
      <div className="category-btn category-hover-header menu-category">
        {/* Bouton pour afficher/masquer la liste des catégories */}
        <button onClick={toggleCategoryListVisibility}>
          {isListVisible ? 'Masquer les catégories' : 'Afficher les catégories'}
        </button>

        {/* Affichage conditionnel de la liste des catégories */}
        {isListVisible && (
          <ul className="category-sub-menu" id="category-active-menu">
            <span className="ml--10">All Categories</span>
            {categories.map(category => (
              <li key={category.id}>
                <CategoryList category={category} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CategoriesPage;
