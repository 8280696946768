import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ProductFeaturedCard from './common/components/ProductFeaturedCard';
import ProductPopularCArd from './common/components/ProductPopularCArd';
import Register from './common/pages/register/Register';
import Login from './common/pages/login/Login';
import About from './common/pages/about/About';
import ShopGridSdebar from './common/pages/shop/ShopGridSdebar';
import ShopGridTopFilter from './common/pages/shop/ShopGridTopFilter';
import ShopListTopFilter from './common/pages/shop/ShopListTopFilter';
import ShopListSidebar from './common/pages/shop/ShopListSidebar';
import ShopDetail from './common/pages/shop/ShopDetail';
import ShopCompare from './common/pages/shop/product feature/ShopCompare';
import ShopDetailsAffiliats from './common/pages/shop/product feature/shopDetailsAffiliats';
import ShopDetailsGroup from './common/pages/shop/product feature/ShopDetailsGroup';
import ShopDetailsVariable from './common/pages/shop/product feature/ShopDetailsVariable';
import Cart from './common/pages/shop/ShopOrders/Cart';
import Checkout from './common/pages/shop/ShopOrders/Checkout';
import Trackorder from './common/pages/shop/ShopOrders/Trackorder';
import VendorDetails from './common/pages/vendors/VendorDetails';
import VendorGrid from './common/pages/vendors/VendorGrid';
import VendorList from './common/pages/vendors/VendorList';
import Store from './common/pages/store/Store';
import Faq from './common/pages/faq/Faq';
import invoice from './common/pages/invoice/invoice';
import contact from './common/pages/contact/contact';
import PrivatePolicy from './common/pages/Privacy policy/PrivatePolicy';
import CookiesPolicy from './common/pages/cookies policy/CookiesPolicy';
import Error from './common/pages/error/Error';
import Account from './common/pages/account/Account';
import Header from './common/pages/home/Header';
import Footer from './common/pages/home/Footer';
import Wishlist from './common/pages/wishlist/Wishlist';
import SousCard from './common/components/SousCard';
import CategoriesPage from './common/components/CategoriesPage';
import SearchBarre from './common/components/SearchBarre';
import Bouton from './common/components/Bouton';
import NosServises from './common/pages/presentation_wemaza_service/NosServises';
import Pharmacie from './common/pages/presentation_wemaza_service/Pharmacie';
import DetailsPartenaire from './common/pages/presentation_wemaza_service/DetailsPartenaire';
import Home from './common/pages/home/home';
import Pricecing from './common/pages/princing/Pricecing';
import Pricecing2 from './common/pages/princing/Pricecing2';
import Pricecing3 from './common/pages/princing/Pricecing3';
import Personnalise from './common/pages/princing/Personnalise';
import ScrollToTop from './common/pages/scrollTop/ScrollTop';
import Paiement from './common/pages/paiement/Paiement';
import RegisterForProtecteur from './common/pages/register/RegisterForProtecteur';
import Register_ from './common/pages/register/Register_';
import Abonnement from './common/pages/princing/Abonnement';
import 'bootstrap/dist/css/bootstrap.min.css';
import Verify from './common/pages/verfiy/Verify';
import SecondPaiement from './common/pages/paiement/SecondPaiement';
import BenficiaryPaiment from './common/pages/paiement/BenficiaryPaiment'; 
import Page404 from './common/pages/page404/Page404';
import Intermediary from './common/pages/intermediaire/Intermediary';
import NewPaiement from './common/pages/paiement/NewPaiement';



const PublicRoutes = () => (

    <Routes>


    </Routes>


);
const PrivateRoutes = () => (
    <></>
    
);



const MesRoutes = () => {


    return (
        <Router>
                <Header />
                <ScrollToTop />

                <Routes>

                    <Route path="/" Component={Home} />
                    <Route path="/card" Component={ProductFeaturedCard} />
                    <Route path="/card2" Component={ProductPopularCArd} />
                    <Route path="/subscribe" Component={Register} />
                    <Route path="/user-connecté" Component={Register_} />
                    <Route path="/login" Component={Login} />
                    <Route path="/About" Component={About} />
                    <Route path="/1/:categorySlug?/:subcategorySlug?/:subsubcategorySlug?" Component={ShopGridSdebar} />
                    <Route path="/2" Component={ShopGridTopFilter} />
                    <Route path="/3" Component={ShopListTopFilter} />
                    <Route path="/4" Component={ShopListSidebar} />
                    <Route path="/5" Component={ShopCompare} />
                    <Route path="/6" Component={ShopDetailsAffiliats} />
                    <Route path="/7" Component={ShopDetailsGroup} />
                    <Route path="/8/:id" Component={ShopDetailsVariable} />
                    <Route path="/9" Component={ShopDetail} />
                    <Route path="/cart" Component={Cart} />
                    <Route path="/11" Component={Checkout} />
                    <Route path="/12" Component={Trackorder} />
                    <Route path="/13" Component={VendorDetails} />
                    <Route path="/14" Component={VendorGrid} />
                    <Route path="/15" Component={VendorList} />
                    <Route path="/16" Component={Store} />
                    <Route path="/17" Component={Faq} />
                    <Route path="/18" Component={invoice} />
                    <Route path="/19" Component={contact} />
                    <Route path="/20" Component={PrivatePolicy} />
                    <Route path="/21" Component={CookiesPolicy} />
                    <Route path="/22" Component={Error} />
                    <Route path="/account" Component={Account} />
                    <Route path="/wishlist" Component={Wishlist} />
                    <Route path="/souscard" Component={SousCard} />
                    <Route path="/CategoriesPage" Component={CategoriesPage} />
                    <Route path="/search" Component={SearchBarre} />
                    <Route path="/btn" Component={Bouton} />
                    <Route path="/pharmacie" Component={NosServises} />
                    <Route path="/hopital" Component={Pharmacie} />
                    <Route path="/detail" Component={DetailsPartenaire} />
                    <Route path="/offres" Component={Pricecing} />
                    <Route path="/abonnements3" Component={Pricecing3} />
                    {/*  <Route path="/personnalise" Component={Personnalise} /> */}
                   {/*  <Route path="/paiement" Component={Paiement} /> */}
                    <Route path="/register" Component={RegisterForProtecteur} />
                    <Route path="/test" Component={Abonnement} />
                    <Route path="/verify" Component={Verify} />
                    <Route path="/linked-protector-beneficiary-account" Component={SecondPaiement} />
                    <Route path="/connected-protector-beneficiary-account" Component={BenficiaryPaiment} />
                    <Route path="*" Component={Page404} />
                    <Route path="/intermediary" Component={Intermediary} />
                    <Route path="/paiement" Component={NewPaiement} />
                    <Route path="/:slug?" element={<Pricecing2/>} />
                </Routes>
                <Footer />            
        </Router>
    );
};

export default MesRoutes;