import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CategoryMenu = ({ category }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedSubCategories, setExpandedSubCategories] = useState([]);

  // Toggle for main category expansion
  const toggleExpand = (e) => {
    e.preventDefault(); // Empêche la redirection lors du clic sur l'icône "+"
    setIsExpanded(!isExpanded);
  };

  // Toggle for individual subcategories expansion
  const toggleSubCategoryExpand = (subCategoryId, e) => {
    e.preventDefault(); // Empêche la redirection lors du clic sur l'icône "+"
    setExpandedSubCategories((prevExpanded) =>
      prevExpanded.includes(subCategoryId)
        ? prevExpanded.filter((id) => id !== subCategoryId)
        : [...prevExpanded, subCategoryId]
    );
  };

  // Vérifie si la catégorie a des sous-catégories
  const hasSubCategories = (cat) => Array.isArray(cat.subCategories) && cat.subCategories.length > 0;

  return (
    <>
      {/* Lien pour la catégorie principale */}
      <Link 
        to={`/1${category.url_slug}`}
        className="menu-item"
      >
        <span>{category.name}</span>
        {/* Affiche "+" ou "-" si la catégorie a des sous-catégories */}
        {hasSubCategories(category) && (
          <i
            onClick={toggleExpand} // Gère uniquement l'expansion
            className={`fa-regular ${isExpanded ? 'fa-minus' : 'fa-plus'}`}
          />
        )}
      </Link>

      {/* Sous-catégories si la catégorie est expandée */}
      {isExpanded && hasSubCategories(category) && (
        <ul className="submenu">
          {category.subCategories.map((subCategory) => (
            <li key={subCategory.id}>
              {/* Lien pour chaque sous-catégorie */}
              <Link
                to={`/1${subCategory.url_slug}`}
                className="mobile-menu-link"
              >
                {subCategory.name}
                {/* Affiche "+" ou "-" si la sous-catégorie a des sous-sous-catégories */}
                {hasSubCategories(subCategory) && (
                  <i
                    onClick={(e) => toggleSubCategoryExpand(subCategory.id, e)} // Gère uniquement l'expansion
                    className={`fa-regular ${expandedSubCategories.includes(subCategory.id) ? 'fa-minus' : 'fa-plus'}`}
                  />
                )}
              </Link>

              {/* Sous-sous-catégories */}
              {expandedSubCategories.includes(subCategory.id) && hasSubCategories(subCategory) && (
                <ul className="submenu" style={{ marginLeft: 20 }}>
                  {subCategory.subCategories.map((subSubCategory) => (
                    <li key={subSubCategory.id}>
                      {/* Lien pour chaque sous-sous-catégorie */}
                      <Link to={`/1${subSubCategory.url_slug}`} className="mobile-menu-link">
                        {subSubCategory.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CategoryMenu;
