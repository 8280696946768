import { blue, green } from '@mui/material/colors';
import { Button } from 'bootstrap';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const Pricecing3 = () => {

    const [checked, setChecked] = useState(false);

    // Fonction pour gérer le changement de l'état de checked
    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(21px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    /* 
        const [couleur,setCouleur] = useState("green")
        const [couleur2,setCouleur2] = useState("blue") 
    
        const handleclick = ()=>{
                setCouleur("blue")
                setCouleur2("green") 
        }
        const handleclick_ = ()=>{   
            setCouleur("green")
            setCouleur2("blue") 
        }  */
    return (

        <div style={{ fontFamily: "Poppins" }}>
            <div className="container-fluid" >

                {/*  <div className="offer-section offer-section1 about-offer-section" data-aos="fade-up" style={{ paddingBottom: 150 }}  >
                    <h2 className="offer-heading">Nos  <span className="support">prestations</span></h2>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img2.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>Scanner</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img2.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>specialiste</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img3.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>generaliste</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 mb-lg-0 mb-md-0 mb-3">
                                <div className="offer-section-box">
                                    <div className="offer-section-inner">
                                        <figure>
                                            <img src="./assets/images/offer-section-img3.png" alt className="img-fluid" />
                                        </figure>
                                    </div>
                                    <h5>Our Mission</h5>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
 */}


                {/* <h2 className="pt-5" style={{ color: "#3873b8" }}>Choisissez votre plan tarifaire abordable</h2> */}


                <div style={{ textAlign: "center" }}>je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}> 4€/mois</span>
                    <FormControlLabel style={{ paddingTop: 9 }}
                        control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleSwitchChange} />}

                    /></div>
                <div className='container-fluid d-flex flex-wrap justify-content-center  mt-3' style={{ marginBottom: 200, marginTop: 50 }}>
                    <div className="d-flex flex-column justify-content-center mb-5" style={{ background: "rgba(56,115,184,0.1", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", minWidth: 310, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                        <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6 }}>Basic</div>

                        {
                            checked ? <>
                                <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}><span style={{ textDecoration: "line-through" }}>9,99€</span> 6,99€/mois</div>
                                <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>pendant 12 mois, 0,99€ le premier mois</div>
                            </>

                                : <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>9,99€/mois</div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                                </>

                        }
                        <div>
                            <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>

                            </ul>
                        </div>
                        <div >
                            <Link to={'/register'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                            <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        </div>
                        <div style={{ height: 40, cursor: "pointer" }}>
                            <Link to={'/user-connecté '}>   <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} /></Link>
                        </div>
                        <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                            Exclusivité Google: -50% la première année
                        </div>


                    </div>
                    <div className="d-flex flex-column justify-content-center mb-5" style={{ position: "relative", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", minWidth: 310, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                        <div style={{ position: "absolute", left: "23%", top: "-9px", background: "#3873b8", color: "#fff", paddingLeft: 4, paddingRight: 4, fontSize: 12 }}>   recommandé pour vous </div>
                        <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6, color: "#3873b8" }}>Medium</div>
                        {
                            checked ? <>
                                <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}><span style={{ textDecoration: "line-through" }}>11,99€</span> 7,99€/mois</div>
                                <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>pendant 12 mois, 0,99€ le premier mois</div>
                            </>

                                : <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>11,99€/mois</div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                                </>

                        }
                        <div>
                            <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>

                            </ul>
                        </div>
                        <div >
                            <Link to={'/register'}>  <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                            <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        </div>
                        <div style={{ height: 40, cursor: "pointer" }}>
                            <Link to={'/user-connecté '}>   <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} /></Link>
                        </div>
                        <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                            Exclusivité Google: -50% la première année
                        </div>


                    </div>
                    <div className="d-flex flex-column justify-content-center mb-5" style={{ background: "rgba(56,115,184,0.1", color: "#1d1d1b", borderColor: "#3873b8", border: "1px solid #3873b8", padding: "24px", textAlign: "center", minWidth: 310, height: "auto", marginRight: 24, fontWeight: "bold" }}>
                        <div style={{ color: "var(--color-grey7,#909090", fontSize: "22px", fontWeight: "bold", marginBottom: 6 }}>Prenium</div>
                        {
                            checked ? <>
                                <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}><span style={{ textDecoration: "line-through" }}>14,99€</span> 10,99€/mois</div>
                                <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>pendant 12 mois, 0,99€ le premier mois</div>
                            </>

                                : <>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 8 }}>14,99€/mois</div>
                                    <div style={{ color: "#7b7b7a", fontSize: '10px', marginBottom: 16, minHeight: 28, fontWeight: "bold" }}>0,99€ le premier mois</div>
                                </>

                        }
                        <div>
                            <ul style={{ fontSize: 12, listStylePosition: "inside" }}>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>
                                <li className='p-2'>Tous les articles débloqués</li>

                            </ul>
                        </div>
                        <div >
                            <Link to={'/register'}>   <button className='btn btn-warning' style={{ borderRadius: "32px", width: "90%", cursor: "pointer", marginTop: 105 }}> s'abonner</button></Link>
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                            <div style={{ margin: "12px 8px", color: "#909090" }}>ou</div>
                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                        </div>
                        <div style={{ height: 40, cursor: "pointer" }}>
                            <Link to={'/user-connecté '}>    <img src='https://boutique.lefigaro.fr/assets/horizon/subscription/sabonner-avec-google-d24b8ea9c5d4e2dd53e80b339acf3a652ea0166ce0aafbaaf135c51622b447af.png' style={{ width: '200px', height: '40px' }} /></Link>
                        </div>
                        <div style={{ marginTop: "12px", fontWeight: "normal", fontSize: 10 }}>
                            Exclusivité Google: -50% la première année
                        </div>


                    </div>
                </div>


            </div>
        </div>

    );
};

export default Pricecing3;