import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import RootReducer from './reducers';
import ReduxThunk from 'redux-thunk';

// On commente les imports de redux-persist
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// Configuration de redux-persist (désactivée)
// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: hardSet,
// };

// const persistedReducer = persistReducer(persistConfig, RootReducer);

// Utiliser simplement le RootReducer sans persistance
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  RootReducer, // On utilise le reducer normal
  composeEnhancers(
    applyMiddleware(ReduxThunk)
  )
);

// persistStore(store); // On commente l'utilisation du persistStore

export default store;
